import React, { useState, useEffect } from 'react'
import {
  reduxForm,
  Form,
  Field,
  submit,
  formValueSelector,
  change,
  getFormValues
} from 'redux-form'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Input from '@material-ui/core/Input'
import { getThemesRSPA } from '../../store/themes/actions'
import DropzoneInput from '../shared/DropzoneInput'
import ShowFiles from '../shared/ShowFiles'

import { Box } from './styles/CreateModuleForm.style'
import ReactSelect from '../../components/select/ReactSelect'
import Stepper from '../shared/Stepper'
import AsyncSelect from '../shared/AsyncSelect'
import {
  dataToOptions,
  dataToOptionsTranslate,
  youtubeParser
} from '../../utils/parsers'
import { occupationsObject } from '../../utils/dicts'
import MaterialInput from '../shared/MaterialInput'
import TextArea from '../shared/TextArea'
import SliderInput from '../shared/SliderInput'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import MobilePreview from '../shared/MobilePreview'
import useMountedRef from '../../utils/useMountedRef'
import { required, validContentAbstract } from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: 0,
    maxWidth: '1110px',
    maxHeight: '100%',
    '& .tox-tinymce': {
      marginBottom: '1rem'
    }
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepButton: {
    zIndex: 999
  },
  mobileStepper: {
    background: 'none'
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  img: {
    width: 280,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 150
  },
  imgTxt: {
    position: 'absolute',
    color: '#fff',
    top: '7rem',
    lineHeight: '8px',
    marginLeft: '10px',
    '& p, h2': {
      fontSize: '11px',
      margin: '0.3rem'
    }
  },
  svgIcon: {
    color: '#FFF',
    padding: 3,
    borderRadius: '50%'
  },
  iconTxt: {
    verticalAlign: 'super',
    marginLeft: '0.2rem',
    fontSize: 9
  },
  selects: {
    backgroundColor: '#FFF',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: 'none'
    }
  },
  disableBtn: {
    backgroundColor: '#F2F2F2',
    border: '1px solid #ADB8CC',
    borderRadius: '8px',
    color: '#F2F2F2',
    height: '2.5rem',
    padding: '8px 12px',
    minWidth: 311
  },
  bull: {
    color: theme.palette.primary.dark
  },
  inputDocs: {
    marginBottom: 40
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 179,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 218,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  addRootStyle: {
    minHeight: 400
  }
}))

const getSteps = {
  1: 'Novo Conteúdo',
  2: 'Adicionar conteúdo',
  3: 'Salvar'
}

const CreateContentForm = ({
  handleSubmit,
  onSubmit,
  submit,
  title,
  categoryValue,
  textContentValue,
  audioValue,
  abstractValue,
  stageValue,
  video,
  change,
  occupationsValue,
  segmentValue,
  themeId,
  segments,
  initialValues,
  getOccupations,
  getCategories,
  occupations,
  categories,
  documentsValue,
  submitSucceeded,
  currentTheme,
  submitting,
  valid,
  touch,
  school,
  history,
  deleteDocument,
  deleteAudio,
  currentContent,
  isEditing,
  isAdmin,
  fieldValues,
  getThemesRSPA,
  contentId,
  occupationToEdit,
  categoryToEdit,
  filesToDelete
}) => {
  const classes = useStyles()
  const mounted = useMountedRef()
  const [activeStep, setActiveStep] = useState(1)
  const [dataToResume, setDataToResume] = useState({})
  const [occupationsOptions, setOccupationsOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [isTextOpen, setIsTextOpen] = useState(false)
  const [previewInfo, setPreviewInfo] = useState({})
  const [fetchedOccupations, setFetchedOccupations] = useState(false)
  const [nameToFilter, setNameToFilter] = React.useState(null)
  const [documents, setDocuments] = React.useState([])

  useEffect(
    () => {
      if (initialValues?.category_id) {
        change('categoryToEdit', initialValues?.category_id)
      }
      if (initialValues?.occupation_id) {
        change('occupationToEdit', initialValues?.occupation_id)
      }
    },
    [initialValues?.category_id, initialValues?.occupation_id]
  )

  // monitorar adição de arquivos
  useEffect(
    () => {
      setDocuments(prev => {
        let newState = []
        prev.forEach(item => newState.push(item))
        documentsValue &&
          documentsValue.forEach(value => {
            newState.push(value)
          })
        const newStateNonDuplicated = newState.filter((item, index, self) => {
          if (item instanceof Blob) {
            return index === self.findIndex(obj => obj.name === item.name)
          } else return true
        })
        return newStateNonDuplicated
      })
    },
    [documentsValue]
  )

  useEffect(
    () => {
      school && change('school_id', school.id)
    },
    [school]
  )

  useEffect(
    () => {
      if (currentTheme && history.location.state?.from === 'createTheme') {
        const themeId =
          currentTheme.item &&
          (currentTheme.item[0]?.id || currentTheme.item?.id)
        change('theme_id', themeId)
      }
    },
    [currentTheme]
  )

  useEffect(
    () => {
      const loadData = async () => {
        if (!occupations?.isFetching) {
          const options = await dataToOptionsTranslate(
            occupations.items,
            occupationsObject
          )
          if (mounted.current) {
            setOccupationsOptions(options)
            if (options.length === 0) {
              setOccupationsOptions([])
            }
          }
        }
      }
      loadData()
    },
    [occupations]
  )

  useEffect(
    () => {
      occupationsValue &&
        getCategories({
          idOcc: occupationsValue.value || occupationsValue,
          idSeg: segmentValue.value || segmentValue
        })
    },
    [occupationsValue]
  )

  useEffect(
    () => {
      const loadData = async () => {
        if (categories?.items.length > 0 && !categories?.isFetching) {
          const options = await dataToOptions(categories.items)
          if (mounted.current) {
            setCategoryOptions(options)
          }
        }
      }
      loadData()
    },
    [categories]
  )

  useEffect(
    () => {
      if (initialValues.segment_id && !fetchedOccupations) {
        getOccupationsValues(initialValues.segment_id)
        setFetchedOccupations(false)
      }
    },
    [contentId]
  )

  useEffect(
    () => {
      const loadCover = async () => {
        const id = themeId?.value || themeId
        id &&
          (await getThemesRSPA({ themeId: id })
            .then(res => {
              res.data &&
                res.data &&
                setPreviewInfo({
                  cover: res.data.cover && res.data.cover.image_url,
                  module: res.data.theme_module && res.data.theme_module.title
                })
            })
            .catch(err => console.error(err)))
      }
      if (activeStep === 2) {
        loadCover()
      }
    },
    [activeStep]
  )

  const handleContentChange = event => {
    setDataToResume(prevState => Object.assign(prevState, { theme: event }))
    change('theme_id', event ? Number(event.value) : event)
  }
  React.useEffect(
    () => {
      const executeIfExist = async () => {
        setDataToResume(prevState =>
          Object.assign(prevState, { segment: fieldValues?.segment_id })
        )
        await getOccupationsValues(fieldValues?.segment_id)
        change('segment_id', fieldValues?.segment_id)
        change('occupation_id', null)
        change('category_id', null)
      }
      fieldValues?.segment_id ? executeIfExist() : setOccupationsOptions([])
    },
    [fieldValues?.segment_id]
  )

  const handleSliderChange = (event, newValue) => {
    change('stage', newValue)
  }

  // const handleUploadFile = (name, filename, file) => {
  //   if (name === 'documents') {
  //     change(name, documentsValue ? [...documentsValue, file] : [file])
  //   } else {
  //     change(name, file)
  //   }
  // }

  // monitorar adição dos documentos ja cadastrados
  useEffect(
    () => {
      initialValues.document_urls &&
        setDocuments(prev => {
          let newState = [...prev]
          initialValues.document_urls.forEach(item => newState.push(item))
          return newState
        })
    },
    [initialValues.document_urls]
  )

  useEffect(
    () => {
      change('audio', initialValues.audio_url)
    },
    [initialValues.audio_url]
  )

  // const arrayRemove = (arr, value) => {
  //   return arr.filter(function (elem) {
  //     return elem.id !== value
  //   })
  // }

  // const handleDeleteFile = (name, doc) => {
  //   if (doc && name === 'documents') {
  //     const newDocumentsValue = arrayRemove(documentsValue, doc.id)
  //     change(name, newDocumentsValue)
  //     if (isEditing) {
  //       deleteDocument({
  //         contentId: currentContent?.item?.data[0]?.id,
  //         documentId: doc.id
  //       })
  //     }
  //   } else {
  //     change(name, null)
  //     initialValues.audio_url = null
  //     const idContent = currentContent?.item?.data[0]?.id
  //     deleteAudio(idContent)
  //   }
  // }

  const getOccupationsValues = async segmentId => {
    await getOccupations(segmentId?.value || segmentId)
  }

  const handleDeleteFilesInstance = fileId => {
    let newState = []
    filesToDelete?.length === 0
      ? newState.push(fileId)
      : (newState = [...(filesToDelete || []), fileId])
    change('filesToDelete', newState)
  }

  const alreadyFilled = value => {
    return value?.length > 0
  }

  const handleDeleteNames = name => {
    if (name) {
      setDocuments(prev => {
        let filteredArray = prev?.filter(obj => {
          return obj?.filename !== name && obj?.name !== name
        })
        return filteredArray
      })
    }
    setNameToFilter(prev => name)
  }

  const schoolParams = isAdmin
    ? { 'q[school_id_null]': true }
    : { 'q[school_id_eq]': school?.id }

  const handleChange = (event, input) => {
    const newValues = Array.isArray(event)
      ? event?.map(v => v.value)
      : event ? event.value : ''
    change(input.name, newValues)
  }
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid xs={12} sm={5} item>
                <label htmlFor='title'>
                  <span>Título do Conteúdo</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={MaterialInput}
                  name='title'
                  placeholder='Título do Conteúdo'
                  validate={[required]}
                  type='text'
                  autoComplete='off'
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <label htmlFor='theme_id'>
                  <span>Tema</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                {history.location.state?.from === 'createTheme' ? (
                  <Input
                    className={classes.disableBtn}
                    type='text'
                    disableUnderline
                    placeholder={
                      history.location.state.title ||
                      currentTheme.item?.attributes?.title
                    }
                    disabled
                  />
                ) : (
                  <Field
                    component={AsyncSelect}
                    id='theme_id'
                    name='theme_id'
                    placeholder='Procure e selecione o Título do Tema'
                    handleAsyncChange={handleContentChange}
                    searchParam='q[title_cont]'
                    defaultValue={
                      (initialValues &&
                        initialValues.theme_id &&
                        initialValues.theme_id) ||
                      dataToResume.theme
                    }
                    touch={touch}
                    request={{
                      path: 'theme',
                      params: schoolParams
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <label htmlFor='segment_id'>
                  <span>Segmento</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={AsyncSelect}
                  id='segment_id'
                  name='segment_id'
                  placeholder='Selecione um segmento'
                  defaultValue={
                    fieldValues?.segment_id ||
                    initialValues?.segment_id ||
                    dataToResume.segment
                  }
                  handleAsyncChange={handleChange}
                  request={{
                    path: 'segment'
                  }}
                  touch={touch}
                  validate={[required]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label htmlFor='occupation_id'>
                  <span>Foco</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  className={classes.selects}
                  component={ReactSelect}
                  name='occupation_id'
                  defaultValue={
                    fieldValues?.occupation_id ||
                    initialValues?.occupation_id?.value
                  }
                  handleChange={handleChange}
                  placeholder='Selecione a Foco'
                  options={occupationsOptions}
                  disabled={!segmentValue || occupationsOptions.length === 0}
                  validate={
                    !alreadyFilled(fieldValues?.occupation_id) && [required]
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label htmlFor='category_id'>
                  <span>Categoria</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  className={classes.selects}
                  component={ReactSelect}
                  handleChange={handleChange}
                  defaultValue={
                    fieldValues?.category_id || initialValues?.category_id
                  }
                  name='category_id'
                  placeholder='Título da Categoria'
                  disabled={!occupationsValue}
                  options={categoryOptions}
                  validate={
                    !alreadyFilled(fieldValues?.category_id) && [required]
                  }
                />
              </Grid>
            </Grid>
            <label htmlFor='stage'>
              <span>Etapa</span>
              <span className={classes.bull}>&bull;</span>
            </label>
            <Field
              component={SliderInput}
              name='stage'
              change={change}
              handleSliderChange={handleSliderChange}
              marks={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' }
              ]}
              min={1}
              max={4}
              step={1}
              valueLabelDisplay='auto'
              validate={[required]}
            />
            <label htmlFor='abstract'>
              <span>Resumo</span>
              <span className={classes.bull}>&bull;</span>
            </label>
            <Field
              component={TextArea}
              name='abstract'
              validate={[required, validContentAbstract]}
              characterCounter='659'
            />
            <ShowFiles
              allFiles={documents}
              handleDeleteFilesInstance={handleDeleteFilesInstance}
              handleDeleteNames={handleDeleteNames}
            />
            <div className={classes.inputDocs}>
              <Field
                accept='.doc, .pdf, .docx, .xlsx, .pptx,
                  .ppt, .jpg, .jpeg, .png, .gif, .heic, video/mp4, video/mov, video/quicktime, video/3gpp, video/3gpp2,
                  audio/mpeg, audio/mpeg3, audio/mp4, audio/m4a, audio/x-m4a, audio/3gpp, audio/3gpp2,
                  application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, .pptx, .ppt'
                component={DropzoneInput}
                maxFiles={50}
                nameToFilter={nameToFilter}
                maxSize={31457280}
                name='documents'
                type='file'
                withoutPreview
              />
            </div>
            {/* <Field
              className={classes.editor}
              name='text_content'
              component={EditorInput}
              defaultValue={textContentValue}
              request={{
                type: 'content_images',
                model: 'content_images',
                otherAttributes: [
                  {
                    name: 'school_id',
                    value: school && school.id
                  }
                ]
              }}
              validate={[required]}
            /> */}
            {/* <label htmlFor='documents'>Documentos</label>
            <UploadButtons>
              <Field
                accept='.xlsx,.xls, .csv, .doc, .docx,.ppt, .pptx,.txt,.pdf'
                id='documents'
                name='documents'
                reference={documentsRef}
                isDrag
                component={UploadInput}
                handleUpload={handleUploadFile}
                handleDelete={handleDeleteFile}
                beforeUpload={false}
              />
              {documentsValue &&
                documentsValue.map(doc => (
                  <React.Fragment key={`key-${doc.name || doc.filename}`}>
                    <span className='fileName'>
                      <InsertDriveFileIcon />
                      {doc.name || doc.filename}
                    </span>
                    <span
                      className='btnDelete'
                      type='button'
                      onClick={() => handleDeleteFile('documents', doc)}
                    >
                      <CancelIcon />
                    </span>
                  </React.Fragment>
                ))}
            </UploadButtons>
            <label htmlFor='audio'>Áudio</label>
            <UploadButtons>
              <Field
                accept='audio/*'
                id='audio'
                name='audio'
                reference={audioRef}
                isDrag
                handleUpload={handleUploadFile}
                handleDelete={handleDeleteFile}
                component={UploadInput}
                beforeUpload={false}
              />
              {audioValue && (
                <>
                  <span className='fileName'>
                    <InsertDriveFileIcon />
                    {audioValue.name || audioValue?.split('/')[7]}
                  </span>
                  <span
                    className='btnDelete'
                    type='button'
                    onClick={() => handleDeleteFile('audio')}
                  >
                    <CancelIcon />
                  </span>
                </>
              )}
            </UploadButtons>
            <label htmlFor='video_url'>Vídeo URL</label>
            <Field
              component={MaterialInput}
              name='video_url'
              placeholder='URL'
              type='text'
            /> */}
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}
            >
              {backButton(() => {
                if (history.location.state?.from === 'createTheme') {
                  const themeId =
                    currentTheme.item &&
                    (currentTheme.item[0]?.id || currentTheme.item?.id)
                  return history.push(`/themes/${themeId}/edit`, {
                    from: 'createContent',
                    themeId: themeId
                  })
                } else {
                  return history.goBack()
                }
              })}
              {nextButton('Avançar', handleNext)}
            </div>
          </Box>
        )
      case 2:
        return (
          <Box>
            <label htmlFor='text_content'>
              <span>Texto</span>
              <span className={classes.bull}>&bull;</span>
            </label>
            <Field
              component={SimpleEditorInput}
              addRootStyle={classes.addRootStyle}
              additionalToolbar={'image media'}
              name='text_content'
              request={{
                type: 'content_images',
                model: 'content_images',
                otherAttributes: [
                  {
                    name: 'school_id',
                    value: school && school.id
                  }
                ]
              }}
              validate={[required]}
            />
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}
            >
              {backButton(handleBack)}
              {nextButton('Avançar', handleNext)}
            </div>
          </Box>
        )
      case 3:
        return (
          <Box>
            <h2 style={{ textAlign: 'center', fontSize: '2.5rem' }}>
              Preview App
            </h2>
            <MobilePreview>
              <Grid container spacing={3}>
                {isTextOpen && (
                  <>
                    {' '}
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                      <div
                        style={{
                          borderBottom: '1px solid #ccc',
                          height: 30,
                          textAlign: 'center',
                          fontSize: 9
                        }}
                      >
                        <h3 style={{ display: 'flex', marginLeft: '1rem' }}>
                          <ArrowBackIcon
                            style={{
                              fontSize: '1rem',
                              verticalAlign: 'sub',
                              marginRight: '1rem'
                            }}
                            onClick={() => setIsTextOpen(false)}
                          />
                          <span style={{ marginLeft: '4rem' }}>
                            {`Etapa ${stageValue}`}
                          </span>
                        </h3>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        margin: '0 20px',
                        maxWidth: 280,
                        fontSize: 10
                      }}
                    >
                      <h2>
                        {dataToResume.theme ||
                        (initialValues && initialValues.theme_id)
                          ? initialValues && initialValues.theme_id
                            ? initialValues.theme_id.label
                            : dataToResume.theme?.label
                          : 'Título do Tema'}
                      </h2>
                      <div
                        style={{ fontSize: 10 }}
                        dangerouslySetInnerHTML={{ __html: textContentValue }}
                      />
                    </Grid>
                  </>
                )}
                {!isTextOpen && (
                  <>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                      <div
                        className={classes.img}
                        style={{
                          backgroundImage: `url(${previewInfo.cover ||
                            'https://stag-api.plataformaversar.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--74d621b73c9708486768a663291d5ab53250d7fb/01_bertoni.png'})`
                        }}
                      />
                      <div className={classes.imgTxt}>
                        <h2>
                          {dataToResume.theme ||
                          (initialValues && initialValues.theme_id)
                            ? initialValues && initialValues.theme_id
                              ? initialValues.theme_id.label
                              : dataToResume.theme?.label
                            : 'Título do Tema'}
                        </h2>
                        <p>
                          {previewInfo?.module
                            ? previewInfo.module
                            : 'Título do Módulo'}
                        </p>
                        <p>07-03-2021</p>
                      </div>
                      <div
                        style={{ borderBottom: '1px solid #ccc', height: 25 }}
                      >
                        <span style={{ margin: '10px', verticalAlign: 'sub' }}>
                          {`Etapa ${stageValue}`}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <NoteOutlinedIcon
                        className={classes.svgIcon}
                        style={{
                          backgroundColor: '#ffa46f',
                          marginLeft: '0.7rem',
                          fontSize: '16px'
                        }}
                      />
                      <span className={classes.iconTxt}>{title}</span>
                      <p style={{ margin: '0.7rem', fontSize: 10 }}>
                        {abstractValue}
                      </p>
                      <div
                        style={{
                          backgroundColor: '#F9F9F9',
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '0.5rem',
                          width: '100%'
                        }}
                        onClick={() => setIsTextOpen(true)}
                      >
                        <span style={{ marginLeft: '0.7rem', fontSize: 10 }}>
                          Leia mais...
                        </span>
                        <ArrowForwardIosIcon
                          style={{
                            fontSize: '0.6rem',
                            verticalAlign: 'sub',
                            marginRight: '1rem'
                          }}
                        />
                      </div>
                    </Grid>
                    {video && (
                      <Grid style={{ marginLeft: '0.7rem' }} item xs={12}>
                        <VideoLibraryIcon
                          className={classes.svgIcon}
                          style={{ backgroundColor: '#ac3e9e' }}
                        />
                        <span className={classes.iconTxt}>Vídeo</span>
                        <iframe
                          width='240'
                          height='135'
                          src={`https://www.youtube.com/embed/${youtubeParser(
                            video
                          )}`}
                          title='YouTube video player'
                          frameBorder='0'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                          allowFullScreen
                        />
                      </Grid>
                    )}
                    {audioValue && (
                      <Grid style={{ marginLeft: '0.7rem' }} item xs={12}>
                        <AudiotrackIcon
                          className={classes.svgIcon}
                          style={{ backgroundColor: '#4b4596' }}
                        />
                        <span className={classes.iconTxt}>Audio</span>
                        <p>{audioValue?.name || audioValue?.split('/')[7]}</p>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </MobilePreview>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}
            >
              {backButton(handleBack)}
              <Button
                className={classes.sendBtn}
                disabled={!valid || submitting}
                onClick={() => submit()}
              >
                Salvar Conteúdo
              </Button>
            </div>
          </Box>
        )
      default:
        return ''
    }
  }

  const nextButton = (name, action) => (
    <Button
      className={classes.continueBtn}
      name='btnNext'
      type='button'
      onClick={action}
    >
      {name}
    </Button>
  )

  const backButton = action => (
    <Button
      className={classes.backBtn}
      name='btnback'
      variant='outlined'
      type='button'
      disabled={submitting}
      onClick={action}
    >
      Voltar
    </Button>
  )

  const handleNext = () => {
    if (
      title &&
      segmentValue &&
      (categoryToEdit || categoryValue) &&
      (occupationToEdit || occupationsValue) &&
      abstractValue &&
      !validContentAbstract(abstractValue) &&
      activeStep === 1 &&
      stageValue &&
      stageValue > 0
    ) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } else if (textContentValue && activeStep === 2) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} steps={getSteps} totalSteps={3} />
      <div>
        <div className={classes.instructions}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {getStepContent(activeStep)}
          </Form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createContentForm')
  const { initialValues } = props
  const attributes = initialValues?.[0]
  const theme = attributes && attributes?.theme
  const segment = attributes && attributes?.segment
  const initValues = {
    title: attributes?.title,
    text_content: attributes && attributes.text_content,
    theme_id: theme && { label: theme.title, value: theme.id },
    school_id: attributes && attributes.school_id,
    segment_id: segment && { label: segment.name, value: segment.id },
    category_id: attributes && String(attributes.category_id),
    occupation_id: attributes && {
      label: String(attributes.occupation.name),
      value: String(attributes.occupation_id)
    },
    audio_url: attributes && attributes.audio_url,
    video_url: attributes && attributes.video_url,
    document_urls: attributes && attributes.document_urls,
    abstract: attributes && attributes.abstract,
    stage: (attributes && attributes.stage) || 1
  }
  return {
    initialValues: initValues,
    filesToDelete: selector(state, 'filesToDelete'),
    title: selector(state, 'title'),
    themeId: selector(state, 'theme_id'),
    occupationsValue: selector(state, 'occupation_id'),
    categoryValue: selector(state, 'category_id'),
    occupationToEdit: selector(state, 'occupationToEdit'),
    categoryToEdit: selector(state, 'categoryToEdit'),
    textContentValue: selector(state, 'text_content'),
    documentsValue: selector(state, 'documents'),
    audioValue: selector(state, 'audio'),
    video: selector(state, 'video_url'),
    segmentValue: selector(state, 'segment_id'),
    abstractValue: selector(state, 'abstract'),
    stageValue: selector(state, 'stage'),
    fieldValues: getFormValues('createContentForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('createContentForm', field, data)),
  submit: () => dispatch(submit('createContentForm')),
  getThemesRSPA: data => dispatch(getThemesRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createContentForm',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      try {
        if (props.currentContent?.item === null) {
          await props.createContents(values)
          props.history.location?.state?.from === 'createTheme'
            ? props.history.push(`/themes/${values.theme_id}/edit`, {
                from: 'createContent',
                themeId: values.theme_id
              })
            : props.history.goBack()
        } else {
          const { categoryToEdit, occupationToEdit } = values
          values.categoryToEdit && delete values.categoryToEdit
          values.occupationToEdit && delete values.occupationToEdit
          delete values.documents
          delete values.document_urls
          delete values.audio_url

          const createFile = doc => {
            let data = fetch(doc.url).then(res => res.blob())
            let file = new File([data], doc.filename)
            return file
          }

          const documents = props.documentsValue?.filter(
            doc => doc instanceof File
          )
          const documentsUrls = props.documentsValue?.filter(
            doc => !(doc instanceof File)
          )
          let docs = []
          let fileAudio = {}
          let anexos = {}

          if (documents?.length > 0) docs = docs.concat(documents) // documentos novos
          if (documentsUrls?.length > 0) {
            // documentos pre-existentes
            const filesDocs = documentsUrls?.map(doc => createFile(doc)) // transforma documentos pre-existentes em arquivos
            docs = docs.concat(filesDocs)
          }
          if (docs.length > 0) {
            anexos.documents = docs
          }

          if (props.audioValue) {
            if (props.audioValue instanceof File) {
              anexos.audio = props.audioValue
            } else {
              const doc = {
                url: props.audioValue,
                filename: props.audioValue.split('/')[7]
              }
              fileAudio = createFile(doc)
              anexos.audio = fileAudio
            }
          }

          await props.editContent({
            id: props.currentContent?.item?.[0]?.id,
            data: {
              ...values,
              category_id:
                values.category_id?.value ||
                values.category_id ||
                categoryToEdit?.value,
              segment_id: values.segment_id?.value || values.segment_id,
              occupation_id:
                values.occupation_id?.value ||
                values.occupation_id ||
                occupationToEdit?.value,
              theme_id: values.theme_id?.value || values.theme_id,
              ...anexos
            }
          })
          props.history.location?.state?.from === 'createTheme'
            ? props.history.push(`/themes/${values.theme_id}/edit`, {
                from: 'createContent',
                themeId: values.theme_id
              })
            : props.history.goBack()
        }
      } catch (err) {
        console.error(err)
      }
    }
  })(CreateContentForm)
)
