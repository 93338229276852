import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import {
  getContentsByIds,
  clearCurrentContent
} from '../store/contents/actions'
import { getTheme, clearCurrentTheme } from '../store/themes/actions'
import ThemeView from '../components/view/ThemeView'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: 0,
    // maxWidth: '1110px',
    maxHeight: '100%'
  },
  header: {
    padding: '1rem 0'
  }
}))

const mapDispatchToProps = {
  getContentsByIds,
  getTheme,
  clearCurrentTheme,
  clearCurrentContent
}

const mapStateToProps = ({ contents, themes, categories, ...state }) => {
  return {
    currentContent: contents.currentItem?.item?.data || {},
    isFetchingContent: contents.currentItem?.isFetching,
    currentTheme: themes.currentItem || {},
    userOccupations: state.auth.currentOccupation
  }
}

const ThemePage = ({
  match,
  getTheme,
  currentTheme,
  getContentsByIds,
  currentContent,
  clearCurrentTheme,
  clearCurrentContent,
  isFetchingContent,
  userOccupations,
  ...props
}) => {
  const classes = useStyles()

  const themeId = match.params?.id
  useEffect(() => {
    getTheme({ id: themeId })

    return () => {
      clearCurrentTheme()
      clearCurrentContent()
    }
  }, [])

  useEffect(
    () => {
      currentTheme?.item &&
        currentTheme.item.contents_order &&
        currentTheme.item.contents_order.length > 0 &&
        getContentsByIds({ ids: currentTheme.item.contents_order.join(',') })
    },
    [currentTheme]
  )

  return (
    <div className={classes.root}>
      {currentTheme.isFetching || isFetchingContent ? (
        <CircularProgress color='secondary' />
      ) : (
        <ThemeView
          theme={currentTheme.item}
          userOccupations={userOccupations}
          themeContents={currentContent}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemePage)
