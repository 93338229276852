import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  reduxForm,
  formValueSelector,
  change,
  getFormValues
} from 'redux-form'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import CheckboxInput from '../input/CheckboxInput'
import { roles, utilsTypes } from '../../utils/constants'

import DatePickerInput, { TimePickerInput } from '../shared/DatePickerInput'
import { makeStyles, withStyles } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Tooltip from '@material-ui/core/Tooltip'
import MaterialModal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import MenuItem from '@material-ui/core/MenuItem'
import RowActionTable from '../table/RowActionTable'

import Paper from '@material-ui/core/Paper'

import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import Button from '../button/Button'
import Input from '../shared/Input'
import Modal from '../modal/Modal'

import UserAvatar from '../shared/UserAvatar'
import menuSVG from '../../assets/icons/menu.svg'
import linkSVG from '../../assets/icons/link.svg'
import bulletinsSVG from '../../assets/icons/bulletins.svg'
import warningSVG from '../../assets/icons/bulletin-warning.svg'
import docSVG from '../../assets/icons/doc.svg'
import bulletinSVG from '../../assets/icons/bulletin.svg'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import {
  getClassroomById,
  clearCurrentClassroom,
  getClassroomsBySchoolIdRSPA,
  getClassroomsByGradeIdRSPA
} from '../../store/classrooms/actions'
import {
  cleanUtilSuccessful,
  createUtils,
  editUtil,
  getUtilById,
  getUtilDestinationsRSPA,
  getUtilForAllDestinationsRSPA,
  editWrapper
} from '../../store/utils/actions'
import { showAlertMessage } from '../../store/alert/actions'
import DropzoneInput from '../shared/DropzoneInput'
import StyledSVG from '../shared/StyledSVG'
import UsersList from '../list/UsersList'
import ReactSelect from '../select/ReactSelect'
import { required, validUtilName } from '../../utils/formUtils'
import { backToThePage } from '../../store/pagination/actions'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  removeButton: {
    position: 'absolute',
    zIndex: 1,
    right: '1px',
    top: '-8px',
    cursor: 'pointer'
  },
  buttons: {
    marginBottom: '32px',
    display: 'flex',
    gap: 16,
    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    }
  },
  confirmBtn: {
    background: 'none !important',
    border: 'none',
    cursor: 'pointer',
    marginRight: '8px !important'
  },
  bottomBtns: {
    display: 'flex',
    marginTop: '40px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    },
    '& button': {
      width: 167
    },
    '& .selfButtons': {
      display: 'flex',
      gap: 10,
      [theme.breakpoints.down('426')]: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        '& button': {
          width: '200px !important'
        }
      }
    }
  },
  checkAllSchool: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props =>
      props.utilType === 'util' ? 'flex-start' : 'flex-end',
    '& p': {
      fontSize: 18,
      fontWeight: 700,
      color: '#555555'
    },
    '& svg': {
      fill: '#555555'
    }
  },
  firstLineFields: {
    display: 'flex',
    '& div.title': {
      width: '800px'
    },
    gap: 16,
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column',
      '& div.title,div.type': {
        width: 255
      },
      gap: 'unset'
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    }
  },
  fields: {
    display: 'flex',
    gap: 16,
    [theme.breakpoints.down('1400')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('426')]: {
      '& div': {
        minWidth: 'unset !important',
        width: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '255px',
          height: '48px'
        }
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    '& div#grade_id, div#classroom_id, div#destination_type': {
      minWidth: 255
    },
    '& .allDay': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  titleMsg: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'baseline',
    gap: 16,
    '& div': {
      maxWidth: '100% !important',
      [theme.breakpoints.up('540')]: {
        minWidth: 380,
        width: 'calc(100% - 252px)'
      }
    }
  },
  btnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      width: 262
    },
    '& button:last-child': {
      marginRight: 0
    }
  },
  destinationsUsers: {
    marginBottom: 26,
    '& span': {
      backgroundColor: '#F6F7F8',
      borderRadius: 32,
      display: 'inline-flex',
      alignItems: 'center',
      gap: 8,
      margin: '5px',
      padding: '5px 10px',
      '& svg': {
        fontSize: 16,
        verticalAlign: 'middle'
      }
    },
    '& button': {
      border: 'none',
      background: 'none',
      color: theme.palette.info.main,
      fontSize: 16,
      fontWeight: 700,
      textDecorationLine: 'underline'
    }
  },
  listFiles: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: 16,
    marginTop: 16,
    height: 'fit-content',
    maxHeight: 250,
    '& img:not(.icon)': {
      borderRadius: 8,
      maxHeight: 250,
      maxWidth: 250,
      objectFit: 'contain',
      width: 'fit-content'
    },
    '& div': {
      alignItems: 'center',
      borderRadius: 8,
      color: '#9B9B9B',
      display: 'flex',
      padding: '6px 8px',
      maxHeight: 250,
      '& img': {
        marginRight: 4
      }
    }
  },
  confirmData: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& label': {
      color: '#555'
    },
    '& .MuiGrid-container': {
      width: 160
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    boxShadow: theme.shadows[5],
    padding: '29px 35px 50px',
    color: theme.palette.primary.main,
    maxHeight: 600,
    width: 825,
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize'
    },
    '& .MuiTableContainer-root': {
      height: 450
    },
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        marginTop: '1rem',
        width: 130
      }
    }
  },
  confirmSpan: {
    display: 'flex',
    alignContent: 'center',
    '& img': {
      margin: 'auto 8px auto 16px'
    }
  },
  closeIcon: {
    fill: '#9B9B9B',
    border: `1.5px solid #9B9B9B`,
    borderRadius: '50%'
  },
  filesListUtil: {
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 26,
    display: 'flex',
    flexFlow: 'wrap',
    gap: 10,
    padding: '6px 8px',
    '& img': {
      marginRight: 4
    },
    '& .link': {
      display: 'flex',
      alignItems: 'center',
      background: 'rgba(218, 218, 218, 0.2)',
      borderRadius: 8,
      padding: 6,
      '& a': {
        color: '#9B9B9B',
        marginRight: 4
      },
      '& svg': {
        fontSize: 14
      }
    }
  },
  addUtilComponent: {
    width: '100%',
    '& .label': {
      marginBottom: '30px',
      maxWidth: '500px',
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: '10px 0 10px 0'
      },
      '& .exampleDoc': {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        // margin: '10px 0 10px 0',
        justifyContent: 'space-evenly',
        width: '240px',
        height: '40px',
        borderRadius: '8px',
        border: '1px solid #ADB8CC',
        '& p': {
          margin: 0,
          cursor: 'pointer',
          color: '#8B598E',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '24px'
        }
      }
    },
    '& .input': {
      width: '100%',
      '& section > div': {
        maxWidth: 'unset !important'
      },
      marginBottom: '30px'
    }
  },
  table: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 700
    },
    '& span': {
      color: '#ADB8CC'
    }
  },
  trashContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: '0.75px solid #ADB8CC',
    width: '24px',
    height: '24px'
  },
  tableHeadRow: {
    '& th:last-child': {
      textAlign: 'end'
    },
    // '& th:first-child': {
    //   paddingRight: '0px',
    //   width: '5%'
    // },
    '& td:last-child': {
      textAlign: 'end'
    }
  },
  action: {
    '& svg': {
      color: '#375F92'
    }
  },
  archiveInTable: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .failure': {
      color: '#EF7C8F'
    }
  },
  submitBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    marginTop: 25,
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  field: {
    minWidth: 200
  }
}))

const UtilsForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  gradeValue,
  schoolId,
  change,
  classroomsValue,
  destinationUsersValue,
  confirmDateLimitValue,
  destinationsValue,
  userOccupations,
  initialValues,
  getUtilById,
  utilType,
  utilId,
  schoolValue,
  scheduledTimeValue,
  endTimeValue,
  isSuccess,
  isError,
  cleanUtilSuccessful,
  destinationTypeValue,
  utilityTypeValue,
  touch,
  isEdit,
  valid,
  alert,
  fieldValues,
  link,
  currentClassroom,
  clearCurrentClassroom,
  getClassroomById,
  backToThePage,
  currentPagination,
  getClassroomsBySchoolIdRSPA,
  getUtilDestinationsRSPA,
  getUtilForAllDestinationsRSPA,
  isFetching
}) => {
  const history = useHistory()
  const classes = useStyles({ utilType })
  const documentsRef = useRef(null)
  const menuRef = useRef(null)
  const selectGradeRef = useRef(null)
  const selectClassroomRef = useRef(null)
  const selectSchoolRef = useRef(null)
  const bulletinInputRef = useRef(null)
  const bulletinViewRef = useRef(null)
  const bulletinEditRef = useRef(null)
  const [checkAllSchool, setCheckAllSchool] = useState(false)
  const [files, setFiles] = useState([])
  const [open, setOpen] = useState(false)
  const [linkOfUtil, setLinkOfUtil] = useState(null)
  const [modalForLink, setModalForLink] = useState(false)
  const [destinationsModal, setDestinationsModal] = useState(false)
  const [pagination, setPagination] = useState({})
  const [classroomsBySchoolCount, setClassroomsBySchoolCount] = useState(0)
  const [sentToAllSchool, setSentToAllSchool] = useState(false)
  const [hasClassroom, setHasClassroom] = useState(false)
  const [bulletinsData, setBulletinsData] = useState([])
  const location = useLocation()
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const today = new Date()
  let fileType = ''
  const destinationValueFilter = destinationsValue.filter(
    (user, index, self) =>
      index === self.findIndex(us => us.value === user.value)
  )
  const AddUtilsFiles = props => {
    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: '#FFFF',
        fontWeight: 700,
        borderBottom: '1px solid #9B9B9B'
      },
      body: {
        fontSize: 14,
        borderBottom: '1px solid #DB8CC'
      }
    }))(TableCell)

    const handleCrudFiles = (event, childName) => {
      setBulletinsData(prev => {
        let countNotEquals = 0
        const newState = prev.map(item => {
          if (
            event.target.files[0].name
              .replace(/_/g, ' ')
              .replace(
                `.${
                  event.target.files[0].name.split('.')[
                    event.target.files[0].name.split('.').length - 1
                  ]
                }`,
                ''
              )
              .toLowerCase() === childName.toLowerCase() &&
            childName.toLowerCase() === item.name.toLowerCase()
          ) {
            const bulletinObjectPreview = event.target.files[0]
            Object.assign(bulletinObjectPreview, {
              preview: URL.createObjectURL(event.target.files[0])
            })
            if (event.target.files[0].size > 20971520) {
              alert('O arquivo deve ter no máximo 20MB.', 'warning')
              return item
            }
            const bulletinChanged = { ...item, bulletin: bulletinObjectPreview }
            return bulletinChanged
          } else {
            countNotEquals += 1
            return item
          }
        })
        if (countNotEquals === prev.length) {
          alert('O nome do arquivo não corresponde ao nome do aluno', 'error')
        }
        return newState
      })
    }
    if (props.utilType !== 'wrapper') {
      if (props.utilType !== 'bulletins') {
        return (
          <>
            <label
              style={{
                fontSize: '16px',
                fontWeight: '700',
                whiteSpace: 'nowrap'
              }}
            >
              Adicionar anexos:
            </label>
            <input
              accept='.pdf, .jpg, .png'
              hidden
              name='files'
              onChange={handleAddFiles}
              ref={menuRef}
              type='file'
            />
            <input
              accept='.doc, .pdf, .docx, .xlsx, .pptx, .ppt'
              hidden
              name='files'
              onChange={handleAddFiles}
              ref={documentsRef}
              type='file'
            />
            <ToggleButtonGroup
              value={fileType}
              exclusive
              onChange={handleFileType}
              aria-label='file types'
              style={{ marginBottom: '1rem', marginRight: '200px' }}
            >
              <Tooltip
                title='Adicionar cardápio'
                aria-label='cardápio'
                style={getUtilityType('menus')}
              >
                <ToggleButton
                  onClick={() => menuRef.current && menuRef.current.click()}
                  value='menu'
                  aria-label='menu'
                >
                  <StyledSVG width={21} height={21} src={menuSVG} />
                </ToggleButton>
              </Tooltip>
              <Tooltip
                title='Adicionar link'
                aria-label='link'
                style={getUtilityType('links')}
              >
                <ToggleButton
                  onClick={() => setModalForLink(prevState => !prevState)}
                  value='link'
                  aria-label='link'
                >
                  <StyledSVG width={21} height={21} src={linkSVG} />
                </ToggleButton>
              </Tooltip>
              <Tooltip
                title='Adicionar documento'
                aria-label='doc'
                style={getUtilityType('documents')}
              >
                <ToggleButton
                  onClick={() =>
                    documentsRef.current && documentsRef.current.click()
                  }
                  value='documents'
                  aria-label='documents'
                >
                  <StyledSVG width={21} height={21} src={docSVG} />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </>
        )
      } else if (!isEdit) {
        return (
          <div className={classes.addUtilComponent}>
            <div className='label'>
              <label style={{ fontSize: '16px', fontWeight: '700' }}>
                Adicionar anexos:
              </label>
              <p>
                Lembre-se, antes de fazer o upload dos seus arquivos verifique
                se o nome de cada arquivo está com o{' '}
                <b>nome completo do aluno</b>. Exemplo:
              </p>
              <div className='exampleDoc'>
                <StyledSVG width={30} height={30} src={bulletinsSVG} />
                <p>João Paulo da Silva.pdf</p>
              </div>
            </div>
            <div className='input'>
              <Field
                accept='application/pdf, image/jpg, image/jpeg, image/png'
                component={DropzoneInput}
                maxFiles={bulletinsData?.length || 0}
                maxSize={20971520}
                name='filesDocs'
                fieldFilled={
                  utilityTypeValue === 'bulletins' && !!fieldValues.classroom_id
                }
                type='file'
                withoutPreview
              />
            </div>
            {bulletinsData?.length > 0 && (
              <>
                <div className='label'>
                  <label style={{ fontSize: '16px', fontWeight: '700' }}>
                    Gerencie seus arquivos:
                  </label>
                  <p>
                    Lista completa dos seus arquivos e seus respectivos destinos
                  </p>
                </div>
                <div className={classes.tableRoot}>
                  <TableContainer component={Paper} elevation={0}>
                    <Table
                      className={classes.table}
                      aria-label='customized table'
                    >
                      <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                          <StyledTableCell>Nome do arquivo</StyledTableCell>
                          <StyledTableCell>Destino</StyledTableCell>
                          <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bulletinsData?.map(item => {
                          return (
                            <TableRow
                              key={item.id}
                              className={classes.tableHeadRow}
                            >
                              <StyledTableCell>
                                {item?.bulletin?.path ||
                                item?.bulletin?.name ? (
                                  <div className={classes.archiveInTable}>
                                    <StyledSVG
                                      width={30}
                                      height={30}
                                      src={bulletinsSVG}
                                    />
                                    <p>
                                      {item?.bulletin?.path ||
                                        item?.bulletin?.name}
                                    </p>
                                  </div>
                                ) : (
                                  <div className={classes.archiveInTable}>
                                    <StyledSVG
                                      width={30}
                                      height={30}
                                      src={warningSVG}
                                    />
                                    <p className='failure'>
                                      Arquivo errado ou inexistente!
                                    </p>
                                  </div>
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                <UserAvatar userName={item.name} />
                              </StyledTableCell>
                              <StyledTableCell>
                                <RowActionTable>
                                  <MenuItem
                                    className={classes.action}
                                    onClick={() =>
                                      bulletinInputRef.current &&
                                      bulletinInputRef.current.click()
                                    }
                                  >
                                    <input
                                      accept='.pdf, .jpg, .jpeg, .png'
                                      hidden
                                      multiple={false}
                                      name='individuaBulletinFile'
                                      onChange={event =>
                                        handleCrudFiles(event, item.name)
                                      }
                                      ref={bulletinInputRef}
                                      type='file'
                                    />
                                    {'Adicionar'}
                                  </MenuItem>
                                  <MenuItem
                                    className={classes.action}
                                    onClick={() =>
                                      bulletinViewRef.current &&
                                      bulletinViewRef.current.click()
                                    }
                                  >
                                    <a
                                      ref={bulletinViewRef}
                                      target='_blank'
                                      href={item?.bulletin?.preview}
                                      rel='noreferrer'
                                    />
                                    {'Visualizar'}
                                  </MenuItem>
                                  <MenuItem
                                    className={classes.action}
                                    onClick={() =>
                                      bulletinInputRef.current &&
                                      bulletinInputRef.current.click()
                                    }
                                  >
                                    <input
                                      accept='.pdf, .jpg, .jpeg, .png'
                                      hidden
                                      multiple={false}
                                      name='individuaBulletinFile'
                                      onChange={event =>
                                        handleCrudFiles(event, item.name)
                                      }
                                      ref={bulletinInputRef}
                                      type='file'
                                    />
                                    {'Editar'}
                                  </MenuItem>
                                </RowActionTable>
                              </StyledTableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        )
      } else {
        return (
          <>
            <label style={{ fontSize: '16px', fontWeight: '700' }}>
              Editar boletim:
            </label>
            <input
              accept='.pdf, .jpg, .jpeg, .png'
              hidden
              name='editFiles'
              onChange={handleEditBulletin}
              ref={bulletinEditRef}
              type='file'
            />
            <Tooltip title='Editar boletim' aria-label='bulletin'>
              <ToggleButton
                onClick={() =>
                  bulletinEditRef.current && bulletinEditRef.current.click()
                }
                value='bulletin'
                aria-label='bulletin'
              >
                <StyledSVG width={21} height={21} src={bulletinSVG} />
              </ToggleButton>
            </Tooltip>
          </>
        )
      }
    } else {
      return <></>
    }
  }

  const goBack = () => {
    backToThePage({ ...currentPagination, canGoBack: true })
    const utilityType = utilityTypeValue || utilType
    history.push({
      pathname: '/utils',
      search: `?name=list&util=${
        utilityType === 'bulletins' ? 'wrapper' : utilityType
      }`,
      state: {
        wrappedId: initialValues?.wrapper_id
      }
    })
  }

  isSuccess && goBack()

  useEffect(
    () => {
      if (bulletinsData?.length > 0) {
        setBulletinsData(prev => {
          let childDocCount = 0
          const newState = prev.map(item => {
            const childDoc = fieldValues?.filesDocs?.find(doc => {
              const childName =
                doc.path
                  .replace(/_/g, ' ')
                  .replace(
                    `.${doc.name.split('.')[doc.name.split('.').length - 1]}`,
                    ''
                  ) ||
                doc.name
                  .replace(/_/g, ' ')
                  .replace(
                    `.${doc.name.split('.')[doc.name.split('.').length - 1]}`,
                    ''
                  )
              return childName?.toLowerCase() === item?.name?.toLowerCase()
            })
            if (childDoc) {
              childDocCount += 1
              return { ...item, bulletin: childDoc }
            } else {
              return { ...item }
            }
          })
          if (childDocCount !== fieldValues?.filesDocs?.length) {
            alert(
              'Alguns arquivos não foram associados, verifique se os nomes estão corretos.',
              'error'
            )
          }
          return newState
        })
      }
    },
    [fieldValues?.filesDocs]
  )

  // to handle get destinations and transform classroomField in a required field if utilType is bulletin
  useEffect(
    () => {
      if (utilityTypeValue === 'bulletins') {
        change('destination_type', 'all')
        if (fieldValues?.grade_id && !fieldValues?.classroom_id) {
          alert(
            'É necessário selecionar uma turma para enviar o boletim.',
            'info'
          )
          setHasClassroom(false)
        } else if (fieldValues?.classroom_id) {
          setHasClassroom(true)
          getClassroomById(fieldValues?.classroom_id)
        }
      }
    },
    [utilityTypeValue, fieldValues?.grade_id, fieldValues?.classroom_id]
  )

  useEffect(
    () => {
      if (!isEdit && utilityTypeValue === 'bulletins') {
        setBulletinsData(prevState => {
          const newState = currentClassroom?.children?.map(item => {
            return { name: item.name, id: item.id, bulletin: null }
          })
          return newState
        })
      }
    },
    [currentClassroom]
  )
  useEffect(
    () => {
      if (bulletinsData?.length > 0) {
        bulletinsData.forEach(item => {
          item?.bulletin &&
            change('bulletins', [...fieldValues?.bulletins, item])
        })
        change('bulletins', bulletinsData)
      }
    },
    [bulletinsData]
  )

  useEffect(async () => {
    clearCurrentClassroom()
    const countSchools =
      isEdit &&
      (await getClassroomsBySchoolIdRSPA({
        schoolId: schoolId,
        onlyCount: true
      }).then(res => res))
    countSchools && setClassroomsBySchoolCount(countSchools)
  }, [])

  useEffect(
    () => {
      if (initialValues?.destinations?.length === classroomsBySchoolCount) {
        setSentToAllSchool(true)
      }
    },
    [classroomsBySchoolCount, initialValues.destinations]
  )

  useEffect(
    () => {
      if (utilId) {
        getUtilById({ id: utilId })
      }
      return () => {
        cleanUtilSuccessful()
      }
    },
    [utilId]
  )

  useEffect(
    () => {
      if (initialValues?.files?.length > 0) {
        change('previousFiles', initialValues.files)
      }
    },
    [initialValues?.files]
  )

  useEffect(
    () => {
      initialValues.linkView && setLinkOfUtil(initialValues.linkView)
    },
    [initialValues.linkView]
  )
  useEffect(
    () => {
      if (isError) {
        setOpen(false)
      }
    },
    [isError]
  )

  const disableSubmit = () => {
    if (utilityTypeValue === 'bulletins') {
      const everyStudentsHasBulletin = fieldValues?.bulletins?.every(
        item => item.bulletin
      )
      return !isEdit
        ? !hasClassroom || !everyStudentsHasBulletin
        : files.length < 1 && link === null && linkOfUtil === null
    } else {
      return !isEdit
        ? files.length < 1 && (link === undefined || link === null)
        : files.length < 1 && link === null && linkOfUtil === null
    }
  }
  const handleCloseBackdrop = () => {
    setOpen(false)
  }

  const handleCheck = async util => {
    !util.target.checked && change('destinations', null)
    selectGradeRef.current.select.clearValue()
    selectClassroomRef.current.select.clearValue()
    setCheckAllSchool(util.target.checked)
    const schoolIdValue = schoolId || schoolValue
    if (schoolIdValue && util.target.checked) {
      // enviar apenas o school_id
      change('schoolId', schoolIdValue)
      destinationTypeValue &&
        handleGetDestinations('checkAllSchool', destinationTypeValue)
    }
  }

  const handleToggle = () => {
    valid && setOpen(!open)
  }

  const handleDeleteLink = isEdit => {
    change('link', null)
    if (isEdit) {
      setLinkOfUtil(null)
    }
  }

  const handleDeleteFile = file => {
    const newValues = files.filter(prev => prev !== file)
    setFiles(newValues)
    change('files', newValues)
  }

  const handleGetDestinations = (name, values, page = 1) => {
    // endpoints by destinationTypeValue:{ 0: teachers, 1: parents, 2: students}
    let value
    if (Array.isArray(values)) {
      value = values.map(val => val.value)
    } else {
      value = values
    }
    const schoolIdValue = schoolId || schoolValue
    if (
      (name === 'destination_type' && value && checkAllSchool) ||
      (name === 'checkAllSchool' && value)
    ) {
      const linksToRequest = {
        teachers: {
          endpoint: 'classroom_teachers',
          params: {
            'q[classroom_school_id_eq]': schoolIdValue,
            'page[number]': page,
            include: 'teacher'
          }
        },
        parents: {
          endpoint: 'child_parents',
          params: {
            'q[child_classrooms_school_id_eq]': schoolIdValue,
            'page[number]': page,
            include: 'parent'
          }
        },
        students: {
          endpoint: 'children',
          params: {
            'q[classrooms_school_id_eq]': schoolIdValue,
            'page[number]': page
          }
        },
        all: {
          endpoint: '/classrooms',
          params: {
            'q[school_id_eq]': schoolIdValue,
            'page[number]': page,
            include: 'teachers,children.parents'
          }
        }
      }
      value === 'all'
        ? getDestinationsSchoolList(linksToRequest[value])
        : getDestinationsList(linksToRequest[value])
    }
    if (name === 'grade_id') {
      const grade = page === 1 ? value : gradeValue
      const linksToRequest = {
        teachers: {
          endpoint: 'classroom_teachers',
          params: {
            'q[classroom_grade_id_in]': grade,
            'q[classroom_school_id_eq]': schoolIdValue,
            'page[number]': page,
            include: 'teacher'
          }
        },
        parents: {
          endpoint: 'child_parents',
          params: {
            'q[child_classrooms_grade_id_in]': grade,
            'q[child_classrooms_school_id_eq]': schoolIdValue,
            'page[number]': page,
            include: 'parent'
          }
        },
        students: {
          endpoint: 'children',
          params: {
            'q[classrooms_grade_id_in]': grade,
            'q[classrooms_school_id_eq]': schoolIdValue,
            'page[number]': page
          }
        },
        all: {
          endpoint: '/classrooms',
          params: {
            'q[school_id_eq]': schoolIdValue,
            'q[grade_id_in]': grade,
            'page[number]': page,
            include: 'teachers,children.parents'
          }
        }
      }
      value && value.length > 0
        ? destinationTypeValue === 'all'
          ? getDestinationsSchoolList(linksToRequest[destinationTypeValue])
          : getDestinationsList(linksToRequest[destinationTypeValue])
        : change('destinations', null)
    }
    if (name === 'classroom_id') {
      const linksToRequest = {
        teachers: {
          endpoint: 'classroom_teachers',
          params: {
            'q[classroom_school_id_eq]': schoolIdValue,
            'q[classroom_id_in]': value,
            'page[number]': page,
            include: 'teacher'
          }
        },
        parents: {
          endpoint: 'child_parents',
          params: {
            'q[child_classrooms_school_id_eq]': schoolIdValue,
            'q[child_classrooms_id_in]': value,
            'page[number]': page,
            include: 'parent'
          }
        },
        students: {
          endpoint: 'children',
          params: {
            'q[classrooms_id_in]': value,
            'q[classrooms_school_id_eq]': schoolIdValue,
            'page[number]': page
          }
        },
        all: {
          endpoint: '/classrooms',
          params: {
            'q[school_id_eq]': schoolIdValue,
            'q[id_in]': value,
            'page[number]': page,
            include: 'teachers,children.parents'
          }
        }
      }
      value && value.length > 0
        ? destinationTypeValue === 'all'
          ? getDestinationsSchoolList(linksToRequest[destinationTypeValue])
          : getDestinationsList(linksToRequest[destinationTypeValue])
        : change('destinations', null)
    }
  }
  const handleChange = (util, input) => {
    touch(input.name)
    let newValue
    if (Array.isArray(util)) {
      newValue = util?.map(v => v)
    } else {
      newValue = util ? util.value : ''
    }
    handleGetDestinations(input.name, newValue)
    change(input.name, newValue)

    // reset classroom_id if grade_id is changed
    if (input.name === 'grade_id') {
      change('classroom_id', null)
      clearCurrentClassroom()
    }
    if (input.name === 'destination_type') {
      setCheckAllSchool(false)
    }
  }

  const handleFileType = (util, newfileType) => {
    fileType = newfileType
  }

  const handleEditBulletin = (util, input) => {
    let file = util.target.files[0]
    const childName = initialValues?.destinations?.[0]?.child?.name
    if (
      file.name
        .replace(/_/g, ' ')
        .replace(
          `.${file.name.split('.')[file.name.split('.').length - 1]}`,
          ''
        )
        .toLowerCase() === childName.toLowerCase() &&
      childName.toLowerCase()
    ) {
      if (file.size > 20971520) {
        alert('O arquivo deve ter no máximo 20MB.', 'warning')
      } else {
        setLinkOfUtil(null)
        setFiles(prevState => {
          const newState = [file]
          change('files', newState)
          return newState
        })
      }
    } else {
      return alert('Nome do arquivo não corresponde ao nome do aluno', 'error')
    }
  }

  const handleAddFiles = (util, input) => {
    let file = util.target.files[0]
    const filesAccept = new Set([
      'pdf',
      'PDF',
      'jpg',
      'JPG',
      'png',
      'PNG',
      'doc',
      'DOC',
      'jpeg',
      'JPEG',
      'xlsx',
      'XLSX',
      'docx',
      'DOCX',
      'pptx',
      'PPTX',
      'PPT',
      'ppt'
    ])

    const suportFileType = file?.name?.split('.')
    const fileType = suportFileType?.[suportFileType?.length - 1]
    if (!filesAccept.has(fileType)) {
      return alert('Formato de documento inválido.', 'warning')
    }

    // application/images - 20MB === 20971520 Bytes
    if (file.size > 20971520) {
      return alert('O arquivo deve ter no máximo 20MB.', 'warning')
    }
    setLinkOfUtil(null)
    setFiles(prevState => {
      const newState = [file]
      change('files', newState)
      return newState
    })
  }

  const getDestinations = page => {
    let fieldType = ''
    if (checkAllSchool) {
      fieldType = 'checkAllSchool'
    } else if (gradeValue && !classroomsValue) {
      fieldType = 'grade_id'
    } else if (classroomsValue) {
      fieldType = 'classroom_id'
    }
    handleGetDestinations(fieldType, destinationTypeValue, page)
  }
  const getUtilityType = util => {
    if (utilityTypeValue === util) {
      return {}
    } else {
      return { display: 'none' }
    }
  }
  const getDestinationsList = async ({ endpoint, params }) => {
    await getUtilDestinationsRSPA({ endpoint, params })
      .then(res => {
        const schoolDestinations = res.data
          .filter(destination => {
            return params.include ? destination[params.include] : destination.id
          })
          .map(item => ({
            value: params.include ? item[params.include].id : item.id,
            label: params.include ? item[params.include].name : item.name
          }))
        setPagination({
          total: res.meta.total_count,
          pageCount: res.meta.page_count
        })
        change(
          'destinations',
          destinationsValue && destinationsModal
            ? destinationsValue.concat(schoolDestinations)
            : schoolDestinations
        )
      })
      .catch(err => console.error(err))
  }

  const getDestinationsSchoolList = async ({ endpoint, params }) => {
    await getUtilForAllDestinationsRSPA({ endpoint, params })
      .then(res => {
        const schoolDestinations = res.data.included
          .map(item => {
            if (item.type === 'users') {
              return { value: item.id, label: item.attributes.name }
            } else if (item.type === 'children' && item.attributes.user_id) {
              return {
                value: item.attributes.user_id,
                label: item.attributes.name
              }
            }
          })
          .filter(Boolean)
        setPagination({
          total: schoolDestinations.length,
          pageCount: res.data.meta.page_count
        })
        change(
          'destinations',
          destinationsValue && destinationsModal
            ? destinationsValue.concat(schoolDestinations)
            : schoolDestinations
        )
      })
      .catch(err => console.error(err))
  }

  // função criada devido um bug do 'validate' do redux form onde ele não reconhece o value estabelecido a partir do initiaValues/defaultValue
  const alreadyFilled = value => {
    return value?.length > 0
  }

  const handleDestinationsModal = () => {
    setDestinationsModal(prevState => !prevState)
  }

  const openUtil = () => {
    let redirectLink
    if (utilType !== 'links') {
      redirectLink = initialValues.file_url
    } else {
      redirectLink = initialValues.note
    }
    redirectLink
      ? window.open(redirectLink, '_blank')
      : alert(`${utilsTypes[utilType]} não anexado`, 'warning')
  }

  const gradeParams =
    destinationTypeValue === 'students' ? { 'q[segment_id_in]': [3, 4] } : {}
  const classroomParams =
    destinationTypeValue === 'students'
      ? { 'q[grade_segment_id_in]': [3, 4] }
      : {}

  if (isFetching) return <LinearProgress color='secondary' />
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isEdit &&
        initialValues?.utility_type === 'bulletins' && (
          <div>
            <p style={{ fontSize: '1.5em' }}>
              <b>Aluno:</b> {initialValues?.destinations?.[0]?.child?.name}
            </p>
          </div>
        )}
      {!isEdit && (
        <div className={classes.destinationsUsers}>
          <div>
            <p
              style={
                destinationValueFilter.length > 0
                  ? { fontSize: '1em', fontWeight: 'bold' }
                  : {}
              }
            >
              Destinatários:
            </p>
            {destinationValueFilter.slice(0, 3).map(destination => {
              return (
                <span key={destination.value || destination.id}>
                  {destination.label || destination?.user?.name}{' '}
                </span>
              )
            })}
            {destinationsValue.length > 3 && (
              <button type='button' onClick={handleDestinationsModal}>
                Ver mais
              </button>
            )}
          </div>
          <Field component={Input} name='destinations' hidden />
        </div>
      )}
      <div className={classes.firstLineFields}>
        <div className='type'>
          <label htmlFor='utility_type'>Tipo: </label>
          <Field
            component={ReactSelect}
            id='utility_type'
            name='utility_type'
            placeholder='Selecione...'
            handleChange={handleChange}
            defaultValue={
              utilityTypeValue === 'wrapper' ? 'bulletins' : utilityTypeValue
            }
            disabled={isEdit}
            touch={touch}
            validate={!alreadyFilled(fieldValues?.utility_type) && [required]}
            options={[
              { label: 'Cardápio', value: 'menus' },
              {
                label: 'Link',
                value: 'links'
              },
              {
                label: 'Documento',
                value: 'documents'
              },
              // envia apenas uma turma sem destination_type
              {
                label: 'Boletim',
                value: 'bulletins'
              }
            ]}
          />
        </div>
        <div className='title'>
          <label htmlFor='title'>Título</label>
          <Field
            component={MaterialInput}
            name='title'
            placeholder='Digite o título...'
            type='text'
            autoComplete='off'
            defaultValue={initialValues.title}
            validate={
              !alreadyFilled(fieldValues?.title) && [required, validUtilName]
            }
          />
        </div>
      </div>
      <div>
        <div className={classes.fields}>
          <div>
            <label htmlFor='destination_type'>Tipo de destinatário</label>
            <Field
              component={ReactSelect}
              id='destination_type'
              name='destination_type'
              placeholder='Selecione...'
              handleChange={handleChange}
              defaultValue={
                destinationTypeValue === null ||
                (utilityTypeValue === 'bulletins' ||
                  utilityTypeValue === 'wrapper')
                  ? 'all'
                  : destinationTypeValue
              }
              disabled={isEdit || utilityTypeValue === 'bulletins'}
              touch={touch}
              validate={
                isEdit &&
                (destinationTypeValue === null || destinationTypeValue === 0)
                  ? undefined
                  : !alreadyFilled(destinationTypeValue) && [required]
              }
              options={[
                { label: 'Docentes', value: 'teachers' },
                {
                  label: 'Responsáveis',
                  value: 'parents'
                },
                {
                  label: 'Estudantes',
                  value: 'students'
                },
                { label: 'Todos', value: 'all' }
              ]}
            />
          </div>
          {isVersarAdmin && (
            <div className={classes.field}>
              <label htmlFor='school_id'>Escola</label>
              <Field
                component={AsyncSelect}
                selectRef={selectSchoolRef}
                defaultOptions={false}
                id='school_id'
                name='school_id'
                placeholder='Digite ou selecione a escola'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                defaultValue={initialValues?.schoolSelect}
                disabled={isEdit}
                validate={
                  isVersarAdmin &&
                  !alreadyFilled(fieldValues?.schoolSelect?.value) && [required]
                }
                request={{
                  path: 'school'
                }}
                touch={touch}
              />
            </div>
          )}
          <div>
            <label htmlFor='grade_id'>Série(s)</label>
            <Field
              component={AsyncSelect}
              cacheUniqs={[destinationTypeValue]}
              disabled={
                checkAllSchool ||
                (utilityTypeValue !== 'bulletins' && !destinationTypeValue) ||
                isEdit
              }
              selectRef={selectGradeRef}
              defaultOptions={false}
              isMulti={utilityTypeValue !== 'bulletins'}
              isClearable={utilityTypeValue === 'bulletins'}
              id='grade_id'
              name='grade_id'
              placeholder='Digite ou selecione'
              handleAsyncChange={handleChange}
              searchParam='q[name_cont]'
              defaultValue={initialValues?.grade?.filter(
                (grade, index) =>
                  initialValues.grade.findIndex(
                    item => item.label === grade.label
                  ) === index
              )}
              // validate={!checkAllSchool && !classroomsValue && [required]}
              validate={
                !isEdit && !checkAllSchool && !classroomsValue && [required]
              }
              request={{
                path: 'grade',
                params: {
                  // se o tipo do destinatário for aluno, filtrar as turmas para ser apenas do to Fundamental II
                  ...gradeParams,
                  'q[school_id_null]': true
                }
              }}
              touch={touch}
            />
          </div>
          <div>
            <label htmlFor='classroom_id'>Turma(s)</label>
            <Field
              component={AsyncSelect}
              disabled={
                checkAllSchool ||
                (utilityTypeValue !== 'bulletins' && !destinationTypeValue) ||
                isEdit
              }
              selectRef={selectClassroomRef}
              cacheUniqs={[gradeValue, schoolValue]}
              id='classroom_id'
              isMulti={utilityTypeValue !== 'bulletins'}
              name='classroom_id'
              placeholder='Digite ou selecione a(s) turma(s)'
              handleAsyncChange={handleChange}
              searchParam='q[name_cont]'
              defaultValue={
                initialValues.classroom ||
                (initialValues.classroom === null &&
                  initialValues.classroom_id && {
                    label: currentClassroom.name,
                    value: currentClassroom.id
                  })
              }
              validate={!isEdit && !checkAllSchool && !gradeValue && [required]}
              request={{
                path: 'classroom',
                // se o tipo do destinatário for aluno, filtrar as turmas para ser apenas do to Fundamental II
                params: {
                  ...classroomParams,
                  'q[school_id_in]': schoolId || schoolValue,
                  'q[grade_id_in]': gradeValue,
                  'q[year_lteq]': new Date().getFullYear() + 1,
                  'q[year_gteq]': new Date().getFullYear()
                }
              }}
              touch={touch}
            />
          </div>
          {isAdmin && (
            <div
              className={classes.checkAllSchool}
              style={
                fieldValues?.utility_type === 'bulletins'
                  ? { display: 'none' }
                  : {}
              }
            >
              <CheckboxInput
                name='school'
                checked={checkAllSchool || sentToAllSchool}
                handleCheck={handleCheck}
                disabled={isEdit || utilityTypeValue === 'bulletins'}
              />
              <p>Enviar para toda escola</p>
            </div>
          )}
        </div>
        <div>
          <div>
            <div className={classes.buttons}>
              <AddUtilsFiles utilType={utilityTypeValue} />
            </div>
            <div />
          </div>
          <div className={classes.fields}>
            <div>
              <label htmlFor='scheduled_at'>Publicação</label>
              <Field
                component={DatePickerInput}
                minDate={!isEdit ? today : undefined}
                id='scheduled_at'
                name='scheduled_at'
                placeholder='dd /mm /aaaa'
                disableToolbar
                defaultValue={initialValues.scheduled_at}
                validate={
                  !alreadyFilled(fieldValues?.scheduled_at) && [required]
                }
              />
            </div>
            <div>
              <label htmlFor='scheduled_at'>Hora de publicação</label>
              <Field
                component={TimePickerInput}
                id='scheduled_at'
                name='scheduled_at'
                placeholder='00:00'
                value={scheduledTimeValue}
                validate={
                  !alreadyFilled(fieldValues?.scheduled_at) && [required]
                }
              />
            </div>
            <div>
              <label htmlFor='final_date'>Término</label>
              <Field
                component={DatePickerInput}
                id='final_date'
                name='final_date'
                placeholder='dd /mm /aaaa'
                disableToolbar
                defaultValue={initialValues.final_date}
                validate={
                  !location?.state?.isIndividual &&
                  (!alreadyFilled(fieldValues?.final_date) && [required])
                }
                minDate={scheduledTimeValue || undefined}
              />
            </div>
          </div>
        </div>
      </div>

      {/* View in post form */}
      <div>
        {link !== undefined && link !== null ? (
          <div className={classes.listFiles}>
            <div style={{ position: 'relative' }} key={link}>
              <img
                className='icon'
                src={linkSVG}
                width={21}
                height={21}
                alt='icone'
              />
              <span style={{ marginRight: '20px' }}>{link}</span>
              <HighlightOffIcon
                color='gray'
                onClick={() => handleDeleteLink(isEdit)}
                className={classes.removeButton}
              />
            </div>
          </div>
        ) : (
          undefined
        )}

        {!!files?.length &&
          files?.length > 0 &&
          !files[0]?.type.includes('image') && (
            <div className={classes.listFiles}>
              {files.map((file, index) => {
                return (
                  <div style={{ position: 'relative' }} key={file.lastModified}>
                    <img
                      className='icon'
                      src={docSVG}
                      width={21}
                      height={21}
                      alt='icone'
                    />
                    <span style={{ marginRight: '20px' }}>{file.name}</span>
                    <HighlightOffIcon
                      color='gray'
                      onClick={() => handleDeleteFile(file)}
                      className={classes.removeButton}
                    />
                  </div>
                )
              })}
            </div>
          )}
        {!!files?.length &&
          files[0].type.includes('image') && (
            <div className={classes.listFiles}>
              {files.map((image, index) => {
                return (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    key={image.lastModified}
                  >
                    <span style={{ marginRight: '20px' }}>{image.name}</span>
                    <img src={URL.createObjectURL(image)} />
                    <HighlightOffIcon
                      color='gray'
                      onClick={() => handleDeleteFile(image)}
                      className={classes.removeButton}
                    />
                  </div>
                )
              })}
            </div>
          )}
      </div>
      <div style={!isEdit ? { display: 'none' } : {}}>
        {/* View in put form */}
        {linkOfUtil ? (
          <div className={classes.listFiles}>
            {utilType === 'links' ? (
              <div
                style={{ position: 'relative', cursor: 'pointer' }}
                key={linkOfUtil}
              >
                <img
                  className='icon'
                  src={linkSVG}
                  width={21}
                  height={21}
                  alt='icone'
                />
                <span
                  onClick={() => openUtil()}
                  style={{ marginRight: '20px' }}
                >
                  {linkOfUtil}
                </span>
                <HighlightOffIcon
                  color='gray'
                  onClick={() => handleDeleteLink(isEdit)}
                  className={classes.removeButton}
                />
              </div>
            ) : initialValues?.content_type?.includes('image') ? (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                key={linkOfUtil}
              >
                <img src={linkOfUtil} />
                <HighlightOffIcon
                  color='gray'
                  onClick={() => handleDeleteLink(isEdit)}
                  className={classes.removeButton}
                />
              </div>
            ) : (
              <div style={{ position: 'relative' }} key={linkOfUtil}>
                <img
                  className='icon'
                  src={docSVG}
                  width={21}
                  height={21}
                  alt='icone'
                />
                <span
                  onClick={() => openUtil()}
                  style={{ marginRight: '20px' }}
                >
                  {linkOfUtil}
                </span>
                <HighlightOffIcon
                  color='gray'
                  onClick={() => {
                    handleDeleteLink(isEdit)
                  }}
                  className={classes.removeButton}
                />
              </div>
            )}
          </div>
        ) : (
          undefined
        )}
      </div>
      <Modal
        open={modalForLink}
        handleClose={() => setModalForLink(prev => !prev)}
      >
        <h2>Adicione um link</h2>
        <Field
          component={MaterialInput}
          name='link'
          placeholder='Digite um link...'
          type='text'
          autoComplete='off'
          defaultValue={initialValues.link}
        />
        <Button
          disabled={!fieldValues?.link?.length > 0}
          className={classes.continueBtn}
          onClick={() => {
            setModalForLink(prev => !prev)
            link?.length > 0 && setLinkOfUtil(null)
          }}
        >
          Continuar
        </Button>
      </Modal>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={destinationsModal}
        onClose={handleDestinationsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={destinationsModal}>
          <UsersList
            fetchItem={getDestinations}
            list={destinationValueFilter}
            pagination={pagination}
            title='Destinatários'
            handleClose={handleDestinationsModal}
          />
        </Fade>
      </MaterialModal>
      <div className={classes.bottomBtns}>
        <div className='selfButtons'>
          <Button
            type='button'
            className={classes.cancelBtn}
            onClick={() => goBack()}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            className={classes.submitBtn}
            disabled={!valid || disableSubmit()}
            onClick={handleToggle}
          >
            Enviar
          </Button>
        </div>
      </div>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color='secondary' />
      </Backdrop>
    </Form>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('utilsForm')
  const current = state.utils.currentItem
  const objGrade = selector(state, 'grade_id')
  const objClassroom = selector(state, 'classroom_id')

  const initValues = {
    ...current,
    content_type: current?.content_type || current?.files?.[0]?.content_type,
    classroom:
      current?.utility_type === 'bulletins' ||
      current?.utility_type === 'wrapper'
        ? [
            {
              label: current?.classroom[0]?.name,
              value: current?.classroom[0]?.id
            }
          ]
        : current?.destinations &&
          current?.destinations[0]?.classroom &&
          current?.destinations?.map(destination => {
            return {
              label: destination.classroom.name,
              value: destination.classroom_id
            }
          }),
    grade:
      current?.utility_type === 'bulletins' ||
      current?.utility_type === 'wrapper'
        ? [
            {
              label: current?.grade[0]?.name,
              value: current?.grade[0]?.id
            }
          ]
        : current?.destinations &&
          current?.destinations[0]?.classroom &&
          current?.destinations?.map(destination => {
            return {
              label: destination?.classroom?.grade?.name,
              value: destination?.classroom?.grade?.id
            }
          }),
    checkSchool:
      current.school_id &&
      current.destinations &&
      !current.destinations[0]?.grade_id &&
      !current.destinations[0]?.classroom_id,
    schoolSelect: current.school_id && {
      label: current.school.name,
      value: current.school.id
    },
    // Atualmente a forma de captura do link é através do include, mas devemos manter
    // o linkView podendo receber também current.file_url por causa dos antigos utilitários
    linkView: current.note || current.file_url || current.files?.[0]?.file_url,
    destination_type: state.utils?.destinations?.type,
    destinationsSelect: state.utils.destinations?.items
      ? state.utils.destinations.items.map(destination => ({
          label: destination.name,
          value: destination.user_id
        }))
      : []
  }
  return {
    initialValues: initValues || {},
    isFetching: state.utils.isFetching,
    isSuccess: state.utils.isSuccess,
    isError: state.utils.isError,
    gradeValue: Array.isArray(objGrade)
      ? objGrade.map(grade => grade.value)
      : objGrade,
    classroomsValue: Array.isArray(objClassroom)
      ? objClassroom.map(classroom => classroom.value)
      : objClassroom,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    currentClassroom: state.classrooms.currentItem,
    schoolValue: selector(state, 'school_id'),
    destinationsValue: selector(state, 'destinations') || [],
    scheduledTimeValue: selector(state, 'scheduled_at'),
    endTimeValue: selector(state, 'final_date'),
    responsibleId: state.auth.user?.data?.id,
    userOccupations: state.auth.currentOccupation,
    destinationTypeValue: selector(state, 'destination_type'),
    utilityTypeValue: selector(state, 'utility_type'),
    link: selector(state, 'link'),
    fieldValues: getFormValues('utilsForm')(state),
    currentPagination: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('utilsForm', field, data)),
  getUtilById: data => dispatch(getUtilById(data)),
  cleanUtilSuccessful: () => dispatch(cleanUtilSuccessful()),
  getClassroomById: data => dispatch(getClassroomById(data)),
  clearCurrentClassroom: () => dispatch(clearCurrentClassroom()),
  backToThePage: data => dispatch(backToThePage(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  getClassroomsBySchoolIdRSPA: data =>
    dispatch(getClassroomsBySchoolIdRSPA.request(data)),
  getUtilDestinationsRSPA: data =>
    dispatch(getUtilDestinationsRSPA.request(data)),
  getUtilForAllDestinationsRSPA: data =>
    dispatch(getUtilForAllDestinationsRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'utilsForm',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      const files = isEqual(values.files, props.initialValues.files)
        ? [values.listImages?.images]
        : [values.listImages?.images, values.files]
      const newValues = {
        title: values.title,
        note: values.link,
        destinationType:
          values.destination_type === 'all' ? null : values.destination_type,
        utility_type: props.utilityTypeValue,
        scheduled_at: values.scheduled_at
          ? moment(values.scheduled_at).format('DD/MM/YYYY HH:mm')
          : null,
        final_date: values.final_date
          ? moment(values.final_date).format('DD/MM/YYYY')
          : null
      }
      // filling files
      if (props.utilityTypeValue === 'bulletins') {
        if (!props.isEdit) {
          const arrayFiles = values.bulletins.map(item => {
            return { id: item.id, file: item.bulletin }
          })
          Object.assign(newValues, {
            files: arrayFiles
          })
        } else {
          Object.assign(newValues, {
            files: files.flat().filter(Boolean),
            previousFiles: values.previousFiles
          })
        }
      } else {
        Object.assign(newValues, {
          files: files.flat().filter(Boolean),
          previousFiles: values.previousFiles
        })
      }
      // finish filling files

      // filling destinations
      const schoolId = props.schoolId || values.school_id
      const gradeValue =
        !values.classroom_id || values.classroom_id.length === 0
          ? values.grade_id
          : []
      const gradeIds = gradeValue && gradeValue.map(grade => +grade.value)

      let classroomsByGradesOrSchool

      if (!props.isEdit) {
        if (gradeIds.length > 0) {
          classroomsByGradesOrSchool = await dispatch(
            getClassroomsByGradeIdRSPA.request({ gradeIds })
          ).then(res => res)
        } else if (!values.classroom_id || values.classroom_id.length === 0) {
          classroomsByGradesOrSchool = await dispatch(
            getClassroomsBySchoolIdRSPA.request({ schoolId })
          ).then(res => res)
        }
      }

      const utilValues = {
        destinationType:
          values.destination_type === 'all' ? null : values.destination_type,
        school_id: schoolId,
        classroom_id: !classroomsByGradesOrSchool
          ? Array.isArray(values.classroom_id)
            ? values.classroom_id.map(classroom => classroom.value)
            : values.classroom_id
          : classroomsByGradesOrSchool &&
            classroomsByGradesOrSchool.map(classroom => classroom.id)
      }
      Object.assign(newValues, { ...utilValues })
      // finish filling destinations

      if (props.isEdit) {
        if (props.utilityTypeValue === 'wrapper') {
          dispatch(
            editWrapper({
              id: props.utilId,
              data: newValues
            })
          )
        } else {
          dispatch(
            editUtil({
              id: props.utilId,
              data: newValues
            })
          )
        }
      } else {
        dispatch(createUtils(newValues))
      }
    }
  })(UtilsForm)
)
