export const roles = {
  VERSAR_ADMIN: 'administrator',
  SCHOOL_ADMIN: 'school_administrator',
  CONTENT_CREATOR: 'content_creator',
  TEACHER: 'teacher',
  SCHOOL_MODERATOR: 'school_moderator',
  CLASSROOM_MODERATOR: 'classroom_moderator',
  COORDINATOR: 'coordinator',
  PARENT: 'parent',
  STUDENT: 'student'
}

export const occupationsIds = {
  VERSAR_ADMIN: '1',
  SCHOOL_ADMIN: '2',
  CONTENT_CREATOR: '3',
  COORDINATOR: '6',
  TEACHER: '8',
  STUDENT: '9',
  PARENT: '10',
  SCHOOL_MODERATOR: '11',
  CLASSROOM_MODERATOR: '12'
}

export const frequencyTags = {
  frequencyForAll: 'Frequência-O aluno compareceu a escola?',
  frequencyForSubject:
    'Frequência por Disciplina-O aluno compareceu a essa disciplina?',
  frequencyForAllTopic: 'O aluno compareceu a escola?',
  frequencyForSubjectTopic: 'O aluno compareceu a essa disciplina?',
  frequencyForAllName: 'Frequência',
  frequencyForSubjectName: 'Frequência por Disciplina'
}

export const shiftOptions = [
  {
    label: 'Manhã',
    value: 'morning'
  },
  {
    label: 'Tarde',
    value: 'afternoon'
  },
  {
    label: 'Integral',
    value: 'fulltime'
  },
  {
    label: 'Noite',
    value: 'night'
  },
  {
    label: 'Turno não definido',
    value: ''
  }
]

export const statusCommunication = {
  pending: 'Moderação',
  ongoing: 'Em andamento',
  done: 'Todas mensagens lidas',
  empty: '-'
}

export const statusMessage = {
  open: 'Em aberto',
  pending: 'Aguardando moderação',
  done: 'Lidas',
  refused: 'Recusada'
}

export const statusEvent = {
  open: 'Em aberto',
  pending: 'Aguardando moderação',
  done: 'Concluído',
  refused: 'Recusada'
}

export const destinationTypeEvent = {
  teachers: 'Docentes',
  parents: 'Responsáveis',
  students: 'Estudantes'
}

export const utilsTypes = {
  menus: 'Cardápio',
  messages: 'Recado',
  documents: 'Documento',
  docs_pdf: 'Doc. PDF',
  links: 'Link',
  bulletins: 'Boletim',
  wrapper: 'Boletins'
}

export const eventsTypes = {
  events: 'evento',
  images: 'foto',
  communications: 'comunicado'
}

export const segmentedTags = {
  schoolsRoutine: [
    'Frequência',
    'Frequência por Disciplina',
    'Atraso',
    'Uniforme',
    'Lição de Casa',
    'Conteúdo Ministrado',
    'Tarefa',
    'Tarefa de casa',
    'Conteúdo do dia',
    'Ocorrências'
  ],
  fooding: [
    'Alimentação',
    'Alimentação Matutino',
    'Alimentação Vespertino',
    'Lanche',
    'Refeições',
    'Alimentação do dia'
  ],
  health: [
    'Higiene',
    'Evacuou',
    'Banho',
    'Necessidades Fisiológicas',
    'Enfermaria'
  ],
  rest: ['Sono', 'Dormiu', 'Hora da soneca'],
  comunication: ['Recado', 'Recado para turma']
}

export const reportStatus = {
  open: 'Em aberto',
  pending: 'Pendente',
  done: 'Concluído',
  waiting_moderation: 'Moderação'
}

export const reportsShift = {
  undefined: 'Não definido',
  morning: 'Manhã',
  afternoon: 'Tarde',
  fulltime: 'Integral',
  night: 'Noite'
}

export const months = {
  '01': 'janeiro',
  '02': 'fevereiro',
  '03': 'março',
  '04': 'abril',
  '05': 'maio',
  '06': 'junho',
  '07': 'julho',
  '08': 'agosto',
  '09': 'setembro',
  '10': 'outubro',
  '11': 'novembro',
  '12': 'dezembro'
}

export const colors = ['#EF7B8E', '#8A588D', '#555555', '#9DD2DA', '#F4A77B']

export const errors = {
  'taxpayer_number#taken': 'CPF inserido já consta em nossos dados.',
  'base#taken':
    'Este perfil já está salvo em nossos dados e você pode tentar reativá-lo.',
  'taxpayer_number#taken/parent':
    'CPF do responsável já consta em nossos dados.'
}
