import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  reduxForm,
  formValueSelector,
  change,
  getFormValues,
  SubmissionError
} from 'redux-form'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import heic2any from 'heic2any'

import { destinationTypeEvent, roles } from '../../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Tooltip from '@material-ui/core/Tooltip'
import MaterialModal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import { backToThePage } from '../../store/pagination/actions'

import Label from '../label/Label'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import Button from '../button/Button'
import CheckboxInput from '../input/CheckboxInput'
// import Input from '../shared/Input'
import DatePickerInput, { TimePickerInput } from '../shared/DatePickerInput'
import Modal from '../modal/Modal'
import ShowRejectAnnouncement from '../shared/ShowRejectAnnouncement'
import ShowPendingAnnouncement from '../shared/ShowPendingAnnouncement'

import videoSVG from '../../assets/icons/video.svg'
import audioSVG from '../../assets/icons/audio.svg'
import imgSVG from '../../assets/icons/img.svg'
import likeSVG from '../../assets/icons/like.svg'
import docSVG from '../../assets/icons/doc.svg'
import linkSVG from '../../assets/icons/link.svg'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {
  cleanEventSuccessful,
  createEvents,
  editEvent,
  getEventById,
  getEventFiles,
  getEventDestinationsRSPA,
  getClassroomsCountBySchoolRSPA,
  getEventForAllDestinationsRSPA
} from '../../store/events/actions'
import ShowFiles from './../shared/ShowFiles'
import {
  getClassroomById,
  clearCurrentClassroom,
  getClassroomsByGradeIdRSPA,
  getClassroomsBySchoolIdRSPA
} from '../../store/classrooms/actions'
import { showAlertMessage } from '../../store/alert/actions'
import StyledSVG from '../shared/StyledSVG'

import DropzoneInput from '../shared/DropzoneInput'
import CalendarSVG from '../../assets/icons/calendar.svg'
import UsersList from '../list/UsersList'
import ReactSelect from '../select/ReactSelect'
import {
  required,
  validAnnouncementTitleMsg,
  validEventName
} from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('750')]: {
      display: 'block',
      marginBottom: '12px !important'
    },
    '& p': {
      fontWeight: 700
    }
  },
  confirmBtn: {
    background: 'none !important',
    border: 'none',
    cursor: 'pointer',
    marginRight: '8px !important',
    padding: '0px'
  },
  bottomBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 167
    }
  },
  checkAllSchool: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props =>
      props.eventType === 'events' ? 'flex-start' : 'flex-end',
    '& p': {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      color: '#00ACDB'
    },
    '& svg': {
      fill: '#00ACDB'
    },
    [theme.breakpoints.down('426')]: {
      '& span': {
        padding: '9px 9px 9px 0px'
      }
    },
    [theme.breakpoints.down('360')]: {
      '& p': {
        fontSize: 14,
        lineHeight: '18px'
      }
    }
  },
  fields: {
    marginBottom: '15px',
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.up('426')]: {
      gap: 16
    },
    '& div#grade_id, div#school_id, div#classroom_id': {
      minWidth: 255
    },
    '& .allDay': {
      display: 'flex',
      alignItems: 'center',
      '& label': {
        color: '#00ACDB',
        fontWeight: 700
      },
      '& svg': {
        fill: '#00ACDB'
      },
      [theme.breakpoints.down('426')]: {
        '& span': {
          padding: '9px 4px 9px 0px !important'
        }
      }
    }
  },
  titleMsg: {
    '& div': {
      '& div.MuiInput-root': {
        width: '525px'
      },
      [theme.breakpoints.up('540')]: {
        minWidth: 380,
        width: '100%'
      },
      [theme.breakpoints.down('820')]: {
        width: '100% !important',
        minWidth: 255
      },
      [theme.breakpoints.down('426')]: {
        width: '255px !important',
        maxWidth: 'unset !important'
      }
    }
  },
  btnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      width: 262
    },
    '& button:last-child': {
      marginRight: 0
    }
  },
  destinationsUsers: {
    marginBottom: '40px',
    '& span': {
      backgroundColor: '#F6F7F8',
      borderRadius: 32,
      display: 'inline-flex',
      alignItems: 'center',
      gap: 8,
      margin: '5px',
      padding: '5px 10px',
      '& svg': {
        fontSize: 16,
        verticalAlign: 'middle'
      },
      [theme.breakpoints.down('426')]: {
        fontSize: '12px'
      }
    },
    '& button': {
      border: 'none',
      background: 'none',
      color: theme.palette.info.main,
      fontSize: 16,
      fontWeight: 700,
      textDecorationLine: 'underline'
    },
    '& p.title': {
      fontWeight: 700,
      fontSize: 14,
      color: '#555555'
    }
  },
  listFiles: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: 16,
    marginTop: 16,
    height: 'fit-content',
    maxHeight: 250,
    '& img:not(.icon)': {
      borderRadius: 8,
      maxHeight: 250,
      maxWidth: 250,
      objectFit: 'contain',
      width: 'fit-content'
    },
    '& div': {
      alignItems: 'center',
      borderRadius: 8,
      color: '#9B9B9B',
      display: 'flex',
      padding: '6px 8px',
      maxHeight: 250,
      '& img': {
        marginRight: 4
      }
    }
  },
  confirmData: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& label': {
      color: '#555'
    },
    '& .MuiGrid-container': {
      width: 160
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    boxShadow: theme.shadows[5],
    padding: '29px 35px 50px',
    color: theme.palette.primary.main,
    maxHeight: 600,
    width: 825,
    '& button': {
      textTransform: 'capitalize'
    },
    '& .MuiTableContainer-root': {
      height: 450
    },
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  confirmSpan: {
    display: 'flex',
    alignContent: 'center',
    '& img': {
      margin: 'auto 8px auto 16px'
    }
  },
  closeIcon: {
    fill: '#9B9B9B',
    border: `1.5px solid #9B9B9B`,
    borderRadius: '50%'
  },
  filesListEvent: {
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 26,
    display: 'flex',
    flexFlow: 'wrap',
    gap: 10,
    padding: '6px 8px',
    '& img': {
      marginRight: 4
    },
    '& .link': {
      display: 'flex',
      alignItems: 'center',
      background: 'rgba(218, 218, 218, 0.2)',
      borderRadius: 8,
      padding: 6,
      '& a': {
        color: '#9B9B9B',
        marginRight: 4
      },
      '& svg': {
        fontSize: 14
      }
    }
  },
  textModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  archives: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 20,
    marginBottom: '1rem',
    '& div': {
      display: 'inline-flex',
      justifyContent: 'end',
      '& svg': {
        color: theme.palette.danger.main,
        cursor: 'pointer',
        position: 'absolute',
        margin: '-14px 14px'
      }
    },
    '& img': {
      maxWidth: 300,
      maxHeight: 194
    }
  },
  button: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    right: -10,
    border: 'none',
    background: 'none'
  },
  link: {
    backgroundColor: 'rgba(218, 218, 218, 0.2)',
    borderRadius: 8,
    display: 'flex',
    gap: 8,
    padding: '5px 10px',
    width: 'fit-content',
    '& a': {
      color: theme.palette.text.secondary,
      textDecoration: 'none'
    }
  },
  img: {
    borderRadius: 8,
    height: 'fit-content',
    objectFit: 'contain'
  },
  cover: {
    border: `1px dashed ${theme.palette.text.secondary}`,
    borderRadius: 8,
    color: theme.palette.text.secondary,
    padding: '9px 14px',
    width: 'fit-content',
    textAlign: 'center'
  },
  btnsModal: {
    display: 'flex',
    gap: 12,
    justifyContent: 'flex-end'
  },
  titleLabel: {
    '& h2': {
      marginBottom: '15px'
    }
  },
  schoolField: {
    minWidth: '270px',
    maxWidth: '70%'
  },
  showMore: {
    '& p': {
      [theme.breakpoints.down('426')]: {
        fontSize: 10
      }
    }
  },
  searchField: {
    '& div': {
      '& .select__control, .MuiInputBase-root': {
        width: '255px'
      }
    },
    [theme.breakpoints.down('426')]: {
      '& div': {
        minWidth: 'unset !important',
        width: 'unset',
        '& .select__control, .MuiInputBase-root': {
          minHeight: '48px',
          height: 'auto'
        }
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    }
  },
  moment: {
    [theme.breakpoints.down('426')]: {
      '& div': {
        '& .select__control, .MuiInputBase-root': {
          height: '48px !important'
        }
      }
    }
  },
  secondSection: {
    flexWrap: 'wrap',
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column'
    }
  },
  description: {
    margin: '10px 0 20px 0',
    [theme.breakpoints.down('426')]: {
      '& div[class^=makeStyles-root]': {
        width: '255px',
        minHeight: '300px',
        marginTop: '10px',
        '& div.tox': {
          height: '300px !important',
          '& .tox-toolbar__primary .tox-toolbar__group': {
            display: 'flex',
            overflow: 'auto'
          }
        }
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div[class^=makeStyles-root]': {
        width: '64vw'
      }
    }
  },
  removeButton: {
    position: 'absolute',
    zIndex: 1,
    right: '1px',
    top: '-8px',
    cursor: 'pointer'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  rejectBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    marginTop: 25,
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  confirmDateBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 100,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const AnnouncementsForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  gradeValue,
  schoolId,
  change,
  classroomsValue,
  confirmDateLimitValue,
  destinationsValue,
  userOccupations,
  initialValues,
  getEventById,
  getEventFiles,
  eventType,
  eventId,
  schoolValue,
  startTimeValue,
  endTimeValue,
  scheduleTimeValue,
  isSuccess,
  isError,
  filesEvent,
  cleanEventSuccessful,
  destinationTypeValue,
  touch,
  getClassroomById,
  isEdit,
  isReview,
  valid,
  alert,
  fieldValues,
  responsibleId,
  editEvent,
  currentClassroom,
  clearCurrentClassroom,
  filesImageValue,
  filesAudioValue,
  filesVideoValue,
  filesDocValue,
  backToThePage,
  currentPagination,
  link,
  getEventDestinationsRSPA,
  getClassroomsCountBySchoolRSPA,
  getEventForAllDestinationsRSPA,
  error
}) => {
  const history = useHistory()
  const classes = useStyles({ eventType })
  const selectGradeRef = useRef(null)
  const selectClassroomRef = useRef(null)
  const selectSchoolRef = useRef(null)
  const selectNameRef = useRef(null)
  const [checkAllSchool, setCheckAllSchool] = useState(false)
  const [checkAllDay, setCheckAllDay] = useState(false)
  const [open, setOpen] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [destinationsModal, setDestinationsModal] = useState(false)
  const [coverValue, setCoverValue] = useState(null)
  const coverImageRef = React.useRef(null)
  const [confirmStatusModal, setConfirmStatusModal] = useState(false)
  // const [pagination, setPagination] = useState({})
  const [filesToDelete, setFilesToDelete] = React.useState([])
  const [nameToFilter, setNameToFilter] = React.useState(null)
  const [files, setFiles] = React.useState([])
  const [canSubmit, setCanSubmit] = React.useState(true)
  const [fileType, setFileType] = useState(null)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const isTeacher = userOccupations?.includes(roles.TEACHER)
  const destinationValueFilter = [
    ...(fieldValues?.destinationUsers?.length > 0
      ? fieldValues?.destinationUsers
      : destinationsValue.filter(
          (user, index, self) =>
            index === self.findIndex(us => us.value === user.value)
        ))
  ]
  const [classroomsBySchoolCount, setClassroomsBySchoolCount] = useState(0)
  const [sentToAllSchool, setSentToAllSchool] = useState(false)
  const [singleton, setSingleton] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const [uploadingHeicCover, setUploadingHeicCover] = React.useState(false)
  const [modalForLink, setModalForLink] = useState(false)
  const [canChangeLink, setCanChangeLink] = useState(false)
  const today = new Date()
  const eventDestinations = Array.from(
    new Set(
      fieldValues?.destinations?.map(destination => {
        return destination.destination_type
          ? destinationTypeEvent[destination.destination_type]
          : 'Todos'
      })
    )
  ).map(item => {
    return { label: item }
  })

  // create because 'goBack' was executing twice
  const goBack = () => {
    if (singleton === false) {
      backToThePage({ ...currentPagination, canGoBack: true })
      history.goBack()
      setSingleton(true)
    }
  }

  isSuccess && goBack()

  useEffect(async () => {
    clearCurrentClassroom()
    const countSchools =
      isEdit &&
      (await getClassroomsCountBySchoolRSPA({ schoolId }).then(res => {
        return res.data.meta
      }))
    countSchools && setClassroomsBySchoolCount(countSchools.total_count)
  }, [])

  useEffect(
    () => {
      files && change('allFiles', files)
      change('coverValue', coverValue)
      filesToDelete && change('filesToDelete', filesToDelete)
    },
    [files, coverValue, filesToDelete]
  )

  useEffect(
    () => {
      if (initialValues?.destinations?.length === classroomsBySchoolCount) {
        setSentToAllSchool(true)
      }
    },
    [classroomsBySchoolCount, initialValues.destinations]
  )

  // não permitir que o link seja exibido enquanto existir outro link (event_file) e o usuário não confirmar a substituição do link
  useEffect(
    () => {
      if (!modalForLink && !canChangeLink) {
        change('link', null)
      }
    },
    [modalForLink]
  )

  // monitorar substituição de link
  useEffect(
    () => {
      if (link && canChangeLink) {
        setFiles(prev => {
          const newState = prev.filter(objc => {
            const isFileOrLinkByApi =
              objc instanceof File || (objc.filename && !objc.note)
            if (!isFileOrLinkByApi) {
              handleDeleteFilesInstance(objc.id)
              return false
            }
            return true
          })
          return newState
        })
      }
      setCanChangeLink(false)
    },
    [canChangeLink, link]
  )

  // monitorar exclusão de arquivos (Blob ou event_file)
  useEffect(
    () => {
      if (nameToFilter !== null) {
        setFiles(prev => {
          let filteredArray = prev?.filter(obj => {
            return (
              obj.name !== nameToFilter &&
              obj.filename !== nameToFilter &&
              obj.note !== nameToFilter
            )
          })
          let prevLink = prev?.filter(obj => obj.note && !obj.filename)
          if (prevLink) {
            change('link', null)
          }
          filesImageValue?.length > 0 &&
            change(
              'filesImage',
              filesImageValue?.filter(
                obj =>
                  obj.name !== nameToFilter && obj.filename !== nameToFilter
              )
            )
          filesAudioValue?.length > 0 &&
            change(
              'filesAudio',
              filesAudioValue?.filter(
                obj =>
                  obj.name !== nameToFilter && obj.filename !== nameToFilter
              )
            )
          filesVideoValue?.length > 0 &&
            change(
              'filesVideo',
              filesVideoValue?.filter(
                obj =>
                  obj.name !== nameToFilter && obj.filename !== nameToFilter
              )
            )
          filesDocValue?.length > 0 &&
            change(
              'filesDocs',
              filesDocValue?.filter(
                obj =>
                  obj.name !== nameToFilter && obj.filename !== nameToFilter
              )
            )
          setNameToFilter(null)
          return filteredArray
        })
      }
    },
    [nameToFilter]
  )
  // monitorar adicao de arquivos (unindo todos na const Files)
  useEffect(
    () => {
      setFiles(prev => {
        let newState = []
        prev.forEach(value => newState.push(value))
        filesImageValue &&
          filesImageValue.forEach(value => {
            newState.push(value)
          })
        filesAudioValue &&
          filesAudioValue.forEach(value => {
            newState.push(value)
          })
        filesVideoValue &&
          filesVideoValue.forEach(value => {
            newState.push(value)
          })
        filesDocValue &&
          filesDocValue.forEach(value => {
            newState.push(value)
          })
        const newStateNonDuplicated = newState.filter((item, index, self) => {
          if (item instanceof Blob && item instanceof File) {
            return index === self.findIndex(obj => obj.name === item.name)
          } else return true
        })
        return newStateNonDuplicated
      })
    },
    [filesImageValue, filesAudioValue, filesVideoValue, filesDocValue]
  )

  useEffect(() => {
    change('isAdmin', isAdmin)
    if (eventId) {
      getEventById({ id: eventId })
      getEventFiles({ id: eventId })
    }
    return () => {
      cleanEventSuccessful()
    }
  }, [])

  // é necessário para editar o evento sem que o admin precise moderar a si mesmo
  useEffect(
    () => {
      !initialValues.isAdmin && change('isAdmin', isAdmin)
    },
    [initialValues]
  )

  useEffect(
    () => {
      initialValues.cover_image_url &&
        setCoverValue(initialValues.cover_image_url)
      initialValues.files &&
        setFiles(prev => {
          let newState = [...prev]
          initialValues.files.forEach(item => newState.push(item))
          return newState
        })
    },
    [initialValues.cover_image_url, initialValues.files]
  )

  useEffect(
    () => {
      if (isError || error) {
        setOpen(false)
        if (error) {
          alert('A série selecionada não possui turmas associadas.', 'error')
        }
      }
    },
    [isError, error]
  )

  useEffect(
    () => {
      if (eventType === 'images') {
        if ((!coverValue || coverValue === 'delete') && !isReview) {
          alert(
            'Nesse formulário é necessário o envio de pelo menos uma foto de capa.',
            'info'
          )
          setCanSubmit(false)
        } else {
          setCanSubmit(true)
        }
      }
    },
    [eventType, coverValue]
  )

  const handleToggle = () => {
    valid && setOpen(!open)
  }

  // const handleGetDestinations = (name, values, page = 1) => {
  //   let value
  //   if (Array.isArray(values)) {
  //     value = values.map(val => val.value)
  //   } else {
  //     value = values
  //   }
  //   const schoolIdValue = schoolId || schoolValue
  //   if (
  //     (name === 'destination_type' && value && checkAllSchool) ||
  //     (name === 'checkAllSchool' && value)
  //   ) {
  //     const linksToRequest = {
  //       teachers: {
  //         endpoint: 'classroom_teachers',
  //         params: {
  //           'q[classroom_school_id_eq]': schoolIdValue,
  //           'q[classrooms_year_lteq]': new Date().getFullYear() + 1,
  //           'q[classrooms_year_gteq]': new Date().getFullYear(),
  //           'page[number]': page,
  //           include: 'teacher'
  //         }
  //       },
  //       parents: {
  //         endpoint: 'child_parents',
  //         params: {
  //           'q[child_classrooms_school_id_eq]': schoolIdValue,
  //           'q[child_classrooms_year_lteq]': new Date().getFullYear() + 1,
  //           'q[child_classrooms_year_gteq]': new Date().getFullYear(),
  //           'page[number]': page,
  //           include: 'parent'
  //         }
  //       },
  //       students: {
  //         endpoint: 'children',
  //         params: {
  //           'q[classrooms_school_id_eq]': schoolIdValue,
  //           'q[user_id_not_null]': true,
  //           'q[classrooms_year_lteq]': new Date().getFullYear() + 1,
  //           'q[classrooms_year_gteq]': new Date().getFullYear(),
  //           'page[number]': page,
  //           include: 'user'
  //         }
  //       },
  //       all: {
  //         endpoint: '/classrooms',
  //         params: {
  //           'q[school_id_eq]': schoolIdValue,
  //           'q[year_lteq]': new Date().getFullYear() + 1,
  //           'q[year_gteq]': new Date().getFullYear(),
  //           'page[number]': page,
  //           include: 'teachers,children.parents'
  //         }
  //       }
  //     }
  //     value === 'all'
  //       ? getDestinationsSchoolList(linksToRequest[value])
  //       : getDestinationsList(linksToRequest[value])
  //   }
  //   if (name === 'grade_id') {
  //     const grade = page === 1 ? value : gradeValue
  //     const linksToRequest = {
  //       teachers: {
  //         endpoint: 'classroom_teachers',
  //         params: {
  //           'q[classroom_grade_id_in]': grade,
  //           'q[classroom_school_id_eq]': schoolIdValue,
  //           'page[number]': page,
  //           include: 'teacher'
  //         }
  //       },
  //       parents: {
  //         endpoint: 'child_parents',
  //         params: {
  //           'q[child_classrooms_grade_id_in]': grade,
  //           'q[child_classrooms_school_id_eq]': schoolIdValue,
  //           'page[number]': page,
  //           include: 'parent'
  //         }
  //       },
  //       students: {
  //         endpoint: 'children',
  //         params: {
  //           'q[classrooms_grade_id_in]': grade,
  //           'q[classrooms_school_id_eq]': schoolIdValue,
  //           'q[user_id_not_null]': true,
  //           'q[classrooms_year_lteq]': new Date().getFullYear() + 1,
  //           'q[classrooms_year_gteq]': new Date().getFullYear(),
  //           'page[number]': page,
  //           include: 'user'
  //         }
  //       },
  //       all: {
  //         endpoint: '/classrooms',
  //         params: {
  //           'q[school_id_eq]': schoolIdValue,
  //           'q[grade_id_in]': grade,
  //           'page[number]': page,
  //           include: 'teachers,children.parents'
  //         }
  //       }
  //     }
  //     value && value.length > 0
  //       ? destinationTypeValue === 'all'
  //         ? getDestinationsSchoolList(linksToRequest[destinationTypeValue])
  //         : getDestinationsList(linksToRequest[destinationTypeValue])
  //       : change('destinations', null)
  //   }
  //   if (name === 'classroom_id') {
  //     const linksToRequest = {
  //       teachers: {
  //         endpoint: 'classroom_teachers',
  //         params: {
  //           'q[classroom_school_id_eq]': schoolIdValue,
  //           'q[classroom_id_in]': value,
  //           'page[number]': page,
  //           include: 'teacher'
  //         }
  //       },
  //       parents: {
  //         endpoint: 'child_parents',
  //         params: {
  //           'q[child_classrooms_school_id_eq]': schoolIdValue,
  //           'q[child_classrooms_id_in]': value,
  //           'page[number]': page,
  //           include: 'parent'
  //         }
  //       },
  //       students: {
  //         endpoint: 'children',
  //         params: {
  //           'q[classrooms_id_in]': value,
  //           'q[classrooms_school_id_eq]': schoolIdValue,
  //           'q[user_id_not_null]': true,
  //           'q[classrooms_year_lteq]': new Date().getFullYear() + 1,
  //           'q[classrooms_year_gteq]': new Date().getFullYear(),
  //           'page[number]': page,
  //           include: 'user'
  //         }
  //       },
  //       all: {
  //         endpoint: '/classrooms',
  //         params: {
  //           'q[school_id_eq]': schoolIdValue,
  //           'q[id_in]': value,
  //           'page[number]': page,
  //           include: 'teachers,children.parents'
  //         }
  //       }
  //     }
  //     value && value.length > 0
  //       ? destinationTypeValue === 'all'
  //         ? getDestinationsSchoolList(linksToRequest[destinationTypeValue])
  //         : getDestinationsList(linksToRequest[destinationTypeValue])
  //       : change('destinations', null)
  //   }
  // }

  // função criada devido um bug do 'validate' do redux form onde ele não reconhece o value estabelecido a partir do initiaValues/defaultValue
  const alreadyFilled = value => {
    return value?.length > 0
  }

  const handleChange = (event, input) => {
    touch(input.name)
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    // handleGetDestinations(input.name, newValue)
    let valueWasFilled = false
    if (input.name === 'destination_type') {
      // set destinations selecteds to null if change destinationType(the first field of form)
      change('destinationUsers', null)
      if (newValue.find(item => item.value === 'all')) {
        change(input.name, [
          { label: 'Docentes', value: 'teachers' },
          { label: 'Responsáveis', value: 'parents' },
          { label: 'Estudantes', value: 'students' }
        ])
        valueWasFilled = true
      }
    }
    if (!valueWasFilled) {
      change(input.name, newValue)
    }
  }

  const handleFileType = (event, newfileType) => {
    if (newfileType && newfileType !== fileType && newfileType !== 'link') {
      newfileType && setFileType(newfileType)
    } else {
      setFileType(null)
    }
  }

  const handleDeleteLink = isEdit => {
    change('link', null)
  }

  const handleAddFiles = async (event, input) => {
    let file = event.target.files[0]
    if (file) {
      const arrayFromSplitName = file.name.split('.')
      const nameWithoutExtension = arrayFromSplitName
        .slice(0, arrayFromSplitName.length - 1)
        .join('-')
      const extension = file.name.split('.')[arrayFromSplitName.length - 1]
      const isHeic = extension.toLowerCase() === 'heic'
      if (isHeic) {
        setUploadingHeicCover(true)
        const resultBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg'
        }).catch(error => {
          console.error(error)
          alert('Erro ao adicionar imagem', 'error')
          return null
        })
        if (resultBlob) {
          setUploadingHeicCover(prev => false)
          const fileJpg = new File(
            [resultBlob],
            `${nameWithoutExtension}.jpeg`,
            {
              type: 'image/jpeg',
              lastModified: new Date().getTime()
            }
          )
          file = Object.assign(fileJpg, {
            preview: URL.createObjectURL(fileJpg)
          })
        } else {
          return null
        }
      }
      file &&
        setCoverValue(prev => {
          change('coverValue', file)
          return file
        })
    }
  }

  const handleCheck = async event => {
    const nameInput = event.target.name
    if (nameInput === 'school') {
      !event.target.checked && change('destinations', null)
      selectGradeRef.current.select.clearValue()
      selectClassroomRef.current.select.clearValue()
      setCheckAllSchool(event.target.checked)
      const schoolIdValue = schoolId || schoolValue
      if (schoolIdValue && event.target.checked) {
        // enviar apenas o school_id
        change('schoolId', schoolIdValue)
        // destinationTypeValue &&
        //   handleGetDestinations('checkAllSchool', destinationTypeValue)
      }
    } else if (nameInput === 'allDay') {
      setCheckAllDay(event.target.checked)
      change('start_time', moment({ hour: 8, minute: 0 }))
      change('end_time', moment({ hour: 18, minute: 0 }))
    }
  }

  // const getDestinations = page => {
  //   let fieldType = ''
  //   if (checkAllSchool) {
  //     fieldType = 'checkAllSchool'
  //   } else if (gradeValue && !classroomsValue) {
  //     fieldType = 'grade_id'
  //   } else if (classroomsValue) {
  //     fieldType = 'classroom_id'
  //   }
  //   // handleGetDestinations(fieldType, destinationTypeValue, page)
  // }

  // const getDestinationsList = async ({ endpoint, params }) => {
  //   await getEventDestinationsRSPA({
  //     endpoint,
  //     params
  //   })
  //     .then(res => {
  //       const schoolDestinations = res.data
  //         .filter(destination => {
  //           return params.include ? destination[params.include] : destination.id
  //         })
  //         .map(item => ({
  //           value: params.include ? item[params.include].id : item.id,
  //           label: params.include ? item[params.include].name : item.name
  //         }))
  //       setPagination({
  //         total: res.meta.total_count,
  //         pageCount: res.meta.page_count
  //       })
  //       change(
  //         'destinations',
  //         destinationsValue && destinationsModal
  //           ? destinationsValue.concat(schoolDestinations)
  //           : schoolDestinations
  //       )
  //     })
  //     .catch(err => console.error(err))
  // }

  // const getDestinationsSchoolList = async ({ endpoint, params }) => {
  //   await getEventForAllDestinationsRSPA({ endpoint, params })
  //     .then(res => {
  //       const schoolDestinations = res.data.included
  //         .map(item => {
  //           if (item.type === 'users') {
  //             return { value: item.id, label: item.attributes.name }
  //           } else if (item.type === 'children' && item.attributes.user_id) {
  //             return {
  //               value: item.attributes.user_id,
  //               label: item.attributes.name
  //             }
  //           }
  //         })
  //         .filter(Boolean)
  //       setPagination({
  //         total: schoolDestinations.length,
  //         pageCount: res.data.meta.page_count
  //       })
  //       change(
  //         'destinations',
  //         destinationsValue && destinationsModal
  //           ? destinationsValue.concat(schoolDestinations)
  //           : schoolDestinations
  //       )
  //     })
  //     .catch(err => console.error(err))
  // }

  const handleConfirmModal = () => {
    setConfirmModal(prevState => !prevState)
  }

  const handleDestinationsModal = () => {
    setDestinationsModal(prevState => !prevState)
  }

  const filesForReview = files
    .filter(item => item?.content_type !== null)
    .map(item => {
      return item.type !== 'event_type' && item instanceof File
    })
    .filter(item => !!item)

  const moderateDate = new Date()
  const defaultValuesForReview = {
    title: fieldValues?.title,
    description: fieldValues?.description,
    destinationType: destinationTypeValue,
    mod_note: fieldValues?.mod_note,
    moderated_at: moment(moderateDate).format('DD/MM/YYYY'),
    refused_at: moment(moderateDate).format('DD/MM/YYYY'),
    moderator_id: responsibleId,
    event_type: eventType,
    files: filesForReview && filesForReview,
    cover_image:
      fieldValues?.coverValue instanceof File
        ? fieldValues?.coverValue
        : undefined,
    start_time: fieldValues?.start_time
      ? moment(fieldValues?.start_time).format('HH:mm')
      : null,
    end_time: fieldValues?.end_time
      ? moment(fieldValues?.end_time).format('HH:mm')
      : null,
    scheduled_at: fieldValues?.scheduled_at
      ? moment(fieldValues?.scheduled_at).format('HH:mm')
      : null,
    event_date: fieldValues?.event_date
      ? moment(fieldValues?.event_date).format('DD/MM/YYYY')
      : null,
    confirmation_date: fieldValues?.confirmation_date
      ? moment(fieldValues?.confirmation_date).format('DD/MM/YYYY')
      : null
  }

  const handleDeleteCover = isntBlob => {
    if (isntBlob) {
      setCoverValue('delete')
    } else {
      setCoverValue(null)
    }
  }

  const handleDeleteFilesInstance = file => {
    setFilesToDelete(prev => {
      let newState = []
      prev.length === 0 ? newState.push(file) : (newState = [...prev, file])
      return newState
    })
  }

  const getFiltersParams = () => {
    if (classroomsValue) {
      return destinationTypeValue?.[0]?.value === 'teachers'
        ? {
            'q[teacher_classroom_teachers_classroom_id_in]': classroomsValue,
            include: 'teacher'
          }
        : destinationTypeValue?.[0]?.value === 'parents'
          ? { 'q[child_classrooms_id_in]': classroomsValue, include: 'parent' }
          : {
              'q[classrooms_id_in]': classroomsValue,
              'q[user_id_not_null]': true,
              include: 'user'
            }
    }
  }

  const handleDeleteNames = name => {
    setNameToFilter(prev => name)
  }

  const gradeParams =
    destinationTypeValue?.length === 1 &&
    destinationTypeValue.some(item => item.value === 'students')
      ? { 'q[segment_id_in]': [3, 4] }
      : {}
  const classroomParams =
    destinationTypeValue?.length === 1 &&
    destinationTypeValue.some(item => item.value === 'students')
      ? { 'q[grade_segment_id_in]': [3, 4] }
      : {}

  return (
    <>
      <div>
        {!isAdmin &&
          initialValues?.status === 'refused' && (
            <ShowRejectAnnouncement rejectMessage={initialValues.mod_note} />
          )}
        {!isAdmin &&
          initialValues?.status === 'pending' && <ShowPendingAnnouncement />}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.titleLabel}>
          <Label>
            {eventType === 'events'
              ? isEdit ? 'Editar evento:' : 'Novo evento:'
              : isReview ? 'Moderação dos campos:' : 'Enviar para:'}
          </Label>
          {/* {!isEdit && (
            <div className={classes.destinationsUsers}>
              <div>
                <p className='title'>Destinatários:</p>
                {destinationValueFilter.slice(0, 3).map(destination => {
                  return (
                    <span key={destination.value || destination.id}>
                      {destination.label || destination?.user?.name}{' '}
                    </span>
                  )
                })}
                {destinationValueFilter.length > 3 && (
                  <button
                    type='button'
                    onClick={handleDestinationsModal}
                    className={classes.showMore}
                  >
                    <p>Ver mais</p>
                  </button>
                )}
              </div>
              <Field component={Input} name='destinations' hidden />
            </div>
          )} */}
        </div>
        {userOccupations?.includes(roles.VERSAR_ADMIN) && (
          <div className={classes.schoolField}>
            <label htmlFor='school_id'>Escola</label>
            <Field
              component={AsyncSelect}
              selectRef={selectSchoolRef}
              defaultOptions={false}
              id='school_id'
              name='school_id'
              placeholder='Digite ou selecione a escola'
              handleAsyncChange={handleChange}
              searchParam='q[name_cont]'
              defaultValue={initialValues?.schoolSelect}
              disabled={isEdit}
              validate={
                userOccupations?.includes(roles.VERSAR_ADMIN) &&
                !alreadyFilled(initialValues?.schoolSelect?.value) && [required]
              }
              request={{
                path: 'school'
              }}
              touch={touch}
            />
          </div>
        )}
        {eventType === 'events' && (
          <div className={classes.fields}>
            <div className={classes.searchField}>
              <label htmlFor='title'>Nome do evento</label>
              <Field
                component={MaterialInput}
                name='title'
                placeholder='Digite o nome do evento'
                type='text'
                autoComplete='off'
                defaultValue={initialValues.title}
                validate={
                  !alreadyFilled(fieldValues?.title) && [
                    required,
                    validEventName
                  ]
                }
              />
            </div>
            <div className={`${classes.searchField} ${classes.moment}`}>
              <label htmlFor='event_date'>Data do evento</label>
              <Field
                component={DatePickerInput}
                id='event_date'
                name='event_date'
                placeholder='dd /mm /aaaa'
                disableToolbar
                defaultValue={initialValues.event_date}
                validate={!alreadyFilled(fieldValues?.event_date) && [required]}
              />
            </div>
            <div className='allDay'>
              <CheckboxInput
                name='allDay'
                checked={checkAllDay}
                handleCheck={handleCheck}
              />
              <label htmlFor='allDay'>O dia todo</label>
            </div>
            <div className={`${classes.searchField} ${classes.moment}`}>
              <label htmlFor='start_time'>Hora início</label>
              <Field
                component={TimePickerInput}
                id='start_time'
                name='start_time'
                placeholder='00:00'
                value={startTimeValue}
                disabled={checkAllDay}
                defaultValue={initialValues.start_time}
                validate={!alreadyFilled(fieldValues?.start_time) && [required]}
              />
            </div>
            <div className={`${classes.searchField} ${classes.moment}`}>
              <label htmlFor='end_time'>Hora término</label>
              <Field
                component={TimePickerInput}
                id='end_time'
                name='end_time'
                placeholder='00:00'
                disabled={checkAllDay}
                value={endTimeValue}
                defaultValue={initialValues.end_time}
                validate={!alreadyFilled(fieldValues?.end_time) && [required]}
              />
            </div>
          </div>
        )}
        <div>
          <div className={classes.fields}>
            <div className={classes.searchField}>
              <label htmlFor='destination_type'>Tipo(s) de destinatário</label>
              <Field
                component={ReactSelect}
                id='destination_type'
                name='destination_type'
                placeholder='Selecione o tipo'
                handleChange={handleChange}
                defaultValue={isEdit ? eventDestinations : destinationTypeValue}
                // defaultValue={isEdit ? eventDestinations : undefined}
                multiple
                disabled={isEdit}
                touch={touch}
                validate={
                  isEdit && destinationTypeValue === null
                    ? undefined
                    : !alreadyFilled(destinationTypeValue) && [required]
                }
                options={[
                  { label: 'Todos', value: 'all' },
                  { label: 'Docentes', value: 'teachers' },
                  {
                    label: 'Responsáveis',
                    value: 'parents'
                  },
                  {
                    label: 'Estudantes',
                    value: 'students'
                  }
                ]}
              />
            </div>
            <div
              style={{ display: isTeacher ? 'none' : '' }}
              className={classes.searchField}
            >
              <label htmlFor='grade_id'>Séries</label>
              <Field
                component={AsyncSelect}
                cacheUniqs={destinationTypeValue}
                disabled={checkAllSchool || !destinationTypeValue || isEdit}
                selectRef={selectGradeRef}
                defaultOptions={false}
                isMulti
                isClearable={false}
                id='grade_id'
                name='grade_id'
                placeholder='Digite ou selecione'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                defaultValue={initialValues?.grade?.filter(
                  (grade, index) =>
                    initialValues.grade.findIndex(
                      item => item.label === grade.label
                    ) === index
                )}
                validate={
                  !checkAllSchool && !classroomsValue && !isEdit && [required]
                }
                request={{
                  path: 'grade',
                  params: {
                    // se o tipo do destinatário for aluno, filtrar as turmas para ser apenas do to Fundamental II
                    ...gradeParams,
                    'q[school_id_null]': true
                  }
                }}
                touch={touch}
              />
            </div>
            <div className={classes.searchField}>
              <label htmlFor='classroom_id'>Turmas</label>
              <Field
                component={AsyncSelect}
                disabled={checkAllSchool || !destinationTypeValue || isEdit}
                selectRef={selectClassroomRef}
                cacheUniqs={[gradeValue, schoolValue, destinationTypeValue]}
                isMulti
                id='classroom_id'
                name='classroom_id'
                placeholder='Digite ou selecione a(s) turma(s)'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                defaultValue={
                  initialValues.classroom ||
                  (initialValues.classroom === null &&
                    initialValues.classroom_id && {
                      label: currentClassroom.name,
                      value: currentClassroom.id
                    })
                }
                validate={
                  !checkAllSchool && !gradeValue && !isEdit && [required]
                }
                request={{
                  path: 'classroom',
                  // se o tipo do destinatário for aluno, filtrar as turmas para ser apenas do to Fundamental II
                  params: {
                    ...classroomParams,
                    'q[school_id_in]': schoolId || schoolValue,
                    'q[grade_id_in]': gradeValue,
                    'q[year_lteq]': new Date().getFullYear() + 1,
                    'q[year_gteq]': new Date().getFullYear()
                  }
                }}
                touch={touch}
              />
            </div>
            <Tooltip
              placement='top'
              title={
                classroomsValue?.length > 1
                  ? 'Selecione apenas uma turma para realizar o envio à destinatários especificos'
                  : ''
              }
            >
              <div
                className={classes.searchField}
                style={{ display: isTeacher ? 'none' : '' }}
              >
                <label htmlFor='destinationUsers'>Destinatários</label>
                <Field
                  disabled={
                    checkAllSchool ||
                    !classroomsValue ||
                    classroomsValue.length === 0 ||
                    classroomsValue.length > 1 ||
                    isEdit ||
                    destinationTypeValue.length > 1
                  }
                  id='destinationUsers'
                  name='destinationUsers'
                  selectRef={selectNameRef}
                  component={AsyncSelect}
                  placeholder='Selecione...'
                  handleAsyncChange={handleChange}
                  cacheUniqs={[
                    destinationTypeValue,
                    classroomsValue,
                    gradeValue,
                    schoolValue
                  ]}
                  isMulti
                  defaultValue={initialValues.destinationsSelect}
                  request={{
                    path:
                      (destinationTypeValue?.[0]?.value === 'teachers' &&
                        'school_teachers') ||
                      (destinationTypeValue?.[0]?.value === 'parents' &&
                        'child_parents') ||
                      'children',
                    params: getFiltersParams()
                  }}
                  touch={touch}
                />
              </div>
            </Tooltip>
            {isAdmin && (
              <div
                className={classes.checkAllSchool}
                style={{ display: isTeacher ? 'none' : '' }}
              >
                <CheckboxInput
                  name='school'
                  checked={checkAllSchool || sentToAllSchool}
                  handleCheck={handleCheck}
                  disabled={isEdit}
                />
                <p>Enviar para toda escola</p>
              </div>
            )}
          </div>
          <div
            style={{ display: 'flex', gap: 16 }}
            className={classes.secondSection}
          >
            <div className={`${classes.searchField} ${classes.moment} dates`}>
              <label htmlFor='scheduled_at'>Data de publicação </label>
              <Field
                component={DatePickerInput}
                id='scheduled_at'
                name='scheduled_at'
                minDate={today}
                placeholder='dd /mm /aaaa'
                disableToolbar
                defaultValue={initialValues.scheduled_at}
                validate={
                  !alreadyFilled(fieldValues?.scheduled_at) && [required]
                }
              />
            </div>
            <div className={`${classes.searchField} ${classes.moment} dates`}>
              <label htmlFor='scheduled_at'>Hora de publicação</label>
              <Field
                component={TimePickerInput}
                id='scheduled_at'
                name='scheduled_at'
                placeholder='00:00'
                value={scheduleTimeValue}
                validate={
                  !alreadyFilled(fieldValues?.scheduled_at) && [required]
                }
              />
            </div>
            {eventType !== 'events' && (
              <div className={`${classes.titleMsg} ${classes.searchField}`}>
                <label htmlFor='title'>Título: </label>
                <Field
                  component={MaterialInput}
                  name='title'
                  type='text'
                  autoComplete='off'
                  validate={
                    !alreadyFilled(fieldValues?.title) && [
                      required,
                      validAnnouncementTitleMsg
                    ]
                  }
                />
              </div>
            )}
          </div>
          <div className={`${classes.searchField} ${classes.description}`}>
            <label htmlFor='title'>Descrição </label>
            <Field
              name='description'
              component={SimpleEditorInput}
              validate={!alreadyFilled(fieldValues?.description) && [required]}
              additionalToolbar={'image media'}
              request={{
                type: 'content_images',
                model: 'content_images',
                otherAttributes: [
                  {
                    name: 'school_id',
                    value: schoolId
                  }
                ]
              }}
            />
          </div>
          <ShowFiles
            handleDeleteCover={handleDeleteCover}
            coverValue={coverValue}
            allFiles={files}
            handleDeleteFilesInstance={handleDeleteFilesInstance}
            handleDeleteNames={handleDeleteNames}
          />
          {link !== undefined && link !== null ? (
            <div className={classes.listFiles}>
              <div style={{ position: 'relative' }} key={link}>
                <img
                  className='icon'
                  src={linkSVG}
                  width={21}
                  height={21}
                  alt='icone'
                />
                <span style={{ marginRight: '20px' }}>{link}</span>
                <HighlightOffIcon
                  color='gray'
                  onClick={() => handleDeleteLink(isEdit)}
                  className={classes.removeButton}
                />
              </div>
            </div>
          ) : (
            undefined
          )}
          {
            <>
              <div className={classes.buttons} style={{ marginBottom: '20px' }}>
                <p>
                  {matches ? 'Adicionar imagem de capa:' : 'Adicionar capa:'}
                </p>
                <div>
                  <div>
                    <input
                      accept='.png, .jpg, .jpeg, .heic'
                      hidden
                      name='files'
                      onChange={handleAddFiles}
                      ref={coverImageRef}
                      type='file'
                    />
                  </div>
                </div>
                {uploadingHeicCover === false ? (
                  <ToggleButtonGroup
                    value={fileType}
                    exclusive
                    onChange={handleFileType}
                    aria-label='file types'
                    style={{ marginBottom: '1rem' }}
                  >
                    <Tooltip title='Adicionar capa' aria-label='capa'>
                      <ToggleButton
                        onClick={() =>
                          coverImageRef.current && coverImageRef.current.click()
                        }
                        value='cover_image'
                        aria-label='image'
                        color='secondary'
                      >
                        <StyledSVG width={21} height={21} src={imgSVG} />
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                ) : (
                  <CircularProgress color='secondary' size={21} />
                )}
                <p>Adicionar anexos:</p>
                <ToggleButtonGroup
                  value={fileType}
                  exclusive
                  onChange={handleFileType}
                  aria-label='file types'
                  style={{ marginBottom: '1rem' }}
                >
                  <Tooltip title='Adicionar foto' aria-label='foto'>
                    <ToggleButton
                      value='image'
                      aria-label='image'
                      color='secondary'
                    >
                      <StyledSVG width={21} height={21} src={imgSVG} />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title='Adicionar vídeo' aria-label='video'>
                    <ToggleButton value='video' aria-label='video'>
                      <StyledSVG width={21} height={21} src={videoSVG} />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title='Adicionar áudio' aria-label='audio'>
                    <ToggleButton value='audio' aria-label='audio'>
                      <StyledSVG width={21} height={21} src={audioSVG} />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title='Adicionar documento' aria-label='document'>
                    <ToggleButton value='document' aria-label='document'>
                      <StyledSVG width={21} height={21} src={docSVG} />
                    </ToggleButton>
                  </Tooltip>
                  {!isTeacher && (
                    <Tooltip title='Adicionar link' aria-label='link'>
                      <ToggleButton
                        onClick={() => setModalForLink(prev => !prev)}
                        value='link'
                        aria-label='link'
                      >
                        <StyledSVG width={21} height={21} src={linkSVG} />
                      </ToggleButton>
                    </Tooltip>
                  )}
                </ToggleButtonGroup>
              </div>

              {fileType === 'image' && (
                <Field
                  accept='.jpg, .jpeg, .png, .gif, .heic'
                  component={DropzoneInput}
                  maxFiles={100}
                  nameToFilter={nameToFilter}
                  maxSize={20971520}
                  name='filesImage'
                  type='file'
                  withoutPreview
                />
              )}
              {fileType === 'video' && (
                <Field
                  accept='video/mp4, video/mov, video/quicktime, video/3gpp, video/3gpp2'
                  component={DropzoneInput}
                  maxFiles={50}
                  nameToFilter={nameToFilter}
                  maxSize={31457280}
                  name='filesVideo'
                  type='file'
                  withoutPreview
                />
              )}
              {fileType === 'audio' && (
                <Field
                  accept='audio/mpeg, audio/mpeg3, audio/mp4, audio/m4a, audio/x-m4a, audio/3gpp, audio/3gpp2'
                  component={DropzoneInput}
                  maxFiles={50}
                  nameToFilter={nameToFilter}
                  maxSize={10485760}
                  name='filesAudio'
                  type='file'
                  withoutPreview
                />
              )}
              {fileType === 'document' && (
                <Field
                  accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, .pptx, .ppt'
                  component={DropzoneInput}
                  maxFiles={50}
                  nameToFilter={nameToFilter}
                  maxSize={10485760}
                  name='filesDocs'
                  type='file'
                  withoutPreview
                />
              )}
            </>
          }
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px'
            }}
          >
            <button
              className={classes.confirmBtn}
              onClick={handleConfirmModal}
              type='button'
            >
              <img
                src={likeSVG}
                width={21}
                height={21}
                alt='icone confirmar recebimento'
              />
            </button>
            <p>Confirmação de presença</p>
            {confirmDateLimitValue && (
              <span className={classes.confirmSpan}>
                <img src={CalendarSVG} alt='ícone calendário' />
                {moment(confirmDateLimitValue).format('DD/MM/YYYY')}
              </span>
            )}
          </div>
        </div>
        <MaterialModal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={confirmModal}
          onClose={handleConfirmModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          // style={{padding: '20px'}}
        >
          <Fade in={confirmModal}>
            <div className={classes.paper}>
              <div className={classes.confirmData}>
                <label htmlFor='confirmation_date'>
                  Data limite para confirmação
                </label>
                <Field
                  component={DatePickerInput}
                  id='confirmation_date'
                  name='confirmation_date'
                  placeholder='dd /mm /aaaa'
                  defaultValue={initialValues.confirmation_date}
                  disableToolbar
                />
              </div>
              <div
                style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}
              >
                <Button
                  onClick={() => {
                    handleConfirmModal()
                    change('confirmation_date', null)
                  }}
                  className={classes.cancelBtn}
                >
                  cancelar
                </Button>
                <Button
                  className={classes.confirmDateBtn}
                  onClick={handleConfirmModal}
                >
                  ok
                </Button>
              </div>
            </div>
          </Fade>
        </MaterialModal>
        <MaterialModal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={destinationsModal}
          onClose={handleDestinationsModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={destinationsModal}>
            <UsersList
              // fetchItem={getDestinations}
              list={destinationValueFilter}
              // pagination={pagination}
              title='Destinatários'
              handleClose={handleDestinationsModal}
            />
          </Fade>
        </MaterialModal>
        <Modal
          open={modalForLink}
          handleClose={() => setModalForLink(prev => !prev)}
        >
          <h2>Adicione um link</h2>
          <Field
            component={MaterialInput}
            name='link'
            placeholder='Digite um link...'
            type='text'
            autoComplete='off'
            defaultValue={initialValues?.link}
          />
          <Button
            disabled={!fieldValues?.link?.length > 0}
            className={classes.continueBtn}
            onClick={() => {
              setModalForLink(prev => !prev)
              setCanChangeLink(true)
            }}
          >
            Continuar
          </Button>
        </Modal>
        <div className={classes.bottomBtns}>
          <Button
            type='button'
            className={classes.cancelBtn}
            onClick={() => {
              goBack()
            }}
          >
            Cancelar
          </Button>
          {isReview && (
            <Button
              type='button'
              variant='outlined'
              className={classes.rejectBtn}
              onClick={() => {
                setConfirmStatusModal(prev => !prev)
              }}
            >
              Rejeitar
            </Button>
          )}
          <Button
            type='submit'
            className={classes.sendBtn}
            disabled={
              !valid ||
              !canSubmit ||
              (!isEdit && !alreadyFilled(destinationTypeValue))
            }
            onClick={handleToggle}
          >
            Enviar
          </Button>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color='secondary' />
        </Backdrop>
        <Modal
          open={confirmStatusModal}
          handleClose={() => setConfirmStatusModal(prev => !prev)}
        >
          <div className={classes.textModal}>
            <h1>Observação: </h1>
            <label htmlFor='mod_note'>Detalhe os motivos da rejeição: </label>
          </div>
          <Field
            component={SimpleEditorInput}
            name='mod_note'
            id='mod_note'
            placeholder='Informe o motivo da rejeição...'
            defaultValue={initialValues.mod_note}
          />
          <div className={classes.btnsModal}>
            <Button
              className={classes.cancelBtn}
              onClick={() => {
                setConfirmStatusModal(prev => !prev)
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.sendBtn}
              onClick={() => {
                editEvent({
                  id: eventId,
                  data: defaultValuesForReview
                })
              }}
            >
              Enviar
            </Button>
          </div>
        </Modal>
      </Form>
    </>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('announcementsForm')
  const current = state.events.currentItem
  const objGrade = selector(state, 'grade_id')
  const objClassroom = selector(state, 'classroom_id')
  const initValues = {
    ...current,
    schedule_time: current?.schedule_at,
    classroom:
      current?.destinations &&
      current?.destinations[0]?.classroom &&
      current?.destinations
        ?.filter(
          (item, index, arr) =>
            arr.findIndex(i => i.classroom_id === item.classroom_id) === index
        )
        .map(destination => ({
          label: destination.classroom.name,
          value: destination.classroom_id
        })),
    grade:
      current?.destinations &&
      current?.destinations[0]?.classroom &&
      current?.destinations?.map(destination => {
        return {
          label: destination.classroom.grade.name,
          value: destination.classroom.grade.id
        }
      }),
    schoolSelect: current.school_id && {
      label: current.school.name,
      value: current.school.id
    },
    destination_type: state.events?.destinations?.type,
    destinationsSelect:
      state.events.currentItem.destinations &&
      state.events.currentItem.destinations.map(destination => {
        if (destination.user_id) {
          return {
            label: destination.user.name,
            value: destination.user_id
          }
        }
      })
  }

  return {
    initialValues: initValues || {},
    isSuccess: state.events.isSuccess,
    isError: state.events.isError,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    gradeValue: Array.isArray(objGrade)
      ? objGrade.map(grade => grade.value)
      : objGrade,
    classroomsValue: Array.isArray(objClassroom)
      ? objClassroom.map(classroom => classroom.value)
      : objClassroom,
    schoolValue: selector(state, 'school_id'),
    destinationsValue: selector(state, 'destinations') || [],
    confirmDateLimitValue: selector(state, 'confirmation_date'),
    scheduleTimeValue: selector(state, 'schedule_at'),
    startTimeValue: selector(state, 'start_time'),
    endTimeValue: selector(state, 'end_time'),
    responsibleId: state.auth.user?.data?.id,
    userOccupations: state.auth.currentOccupation,
    filesEvent: selector(state, 'filesEvent') || current.files,
    destinationTypeValue: selector(state, 'destination_type'),
    fieldValues: getFormValues('announcementsForm')(state),
    currentClassroom: state.classrooms.currentItem,
    filesImageValue: selector(state, 'filesImage'),
    filesAudioValue: selector(state, 'filesAudio'),
    filesVideoValue: selector(state, 'filesVideo'),
    filesDocValue: selector(state, 'filesDocs'),
    link: selector(state, 'link'),
    currentPagination: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('announcementsForm', field, data)),
  getEventById: data => dispatch(getEventById(data)),
  getClassroomById: data => dispatch(getClassroomById(data)),
  getEventFiles: data => dispatch(getEventFiles(data)),
  clearCurrentClassroom: () => dispatch(clearCurrentClassroom()),
  backToThePage: data => dispatch(backToThePage(data)),
  cleanEventSuccessful: () => dispatch(cleanEventSuccessful()),
  getEventDestinationsRSPA: data =>
    dispatch(getEventDestinationsRSPA.request(data)),
  getClassroomsCountBySchoolRSPA: data =>
    dispatch(getClassroomsCountBySchoolRSPA.request(data)),
  getEventForAllDestinationsRSPA: data =>
    dispatch(getEventForAllDestinationsRSPA.request(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  editEvent: data => dispatch(editEvent(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'announcementsForm',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      let filesBlob = []
      let filesInstance = []
      values.allFiles.forEach(item => {
        if (item.type === 'event_files') {
          filesInstance.push(item)
        } else if (item instanceof Blob && item instanceof File) {
          filesBlob.push(item)
        }
      })
      const startTime =
        values.event_date &&
        values.start_time &&
        `${moment(values.event_date).format('DD/MM/YYYY')} ${moment(
          values.start_time
        ).format('HH:mm')}`
      const endTime =
        values.event_date &&
        values.end_time &&
        `${moment(values.event_date).format('DD/MM/YYYY')} ${moment(
          values.end_time
        ).format('HH:mm')}`
      const startTimeValue = startTime && moment(startTime, 'DD/MM/YYYY HH:mm')
      const endTimeValue = endTime && moment(endTime, 'DD/MM/YYYY HH:mm')
      const newValues = {
        title: values.title,
        description: values.description,
        event_type: props.eventType,
        destinations: values?.destinationUsers,
        files: filesBlob,
        links: values.link,
        cover_image: values.coverValue,
        scheduled_at: values.scheduled_at
          ? moment(values.scheduled_at).format('DD/MM/YYYY HH:mm')
          : null,
        start_time: startTimeValue
          ? moment(startTimeValue).format('DD/MM/YYYY HH:mm')
          : null,
        end_time: endTimeValue
          ? moment(endTimeValue).format('DD/MM/YYYY HH:mm')
          : null,
        event_date: values.event_date
          ? moment(values.event_date).format('DD/MM/YYYY')
          : null,
        confirmation_date: values.confirmation_date
          ? moment(values.confirmation_date).format('DD/MM/YYYY')
          : null,
        user_id: props.responsibleId
      }
      const moderateDate = new Date()
      if (values.isAdmin || props.isReview) {
        if (props.isReview) {
          delete newValues.user_id
        }
        Object.assign(newValues, {
          moderated_at: moment(moderateDate).format('DD/MM/YYYY'),
          moderator_id: props.responsibleId
        })
      }
      const schoolId = props.schoolId || values.school_id
      const gradeValue =
        !values.classroom_id || values.classroom_id.length === 0
          ? values.grade_id
          : []
      const gradeIds = gradeValue && gradeValue.map(grade => +grade.value)

      let classroomsByGradesOrSchool

      if (!props.isEdit) {
        if (gradeIds.length > 0) {
          classroomsByGradesOrSchool = await dispatch(
            getClassroomsByGradeIdRSPA.request({ gradeIds, schoolId })
          ).then(res => res)
        } else if (!values.classroom_id || values.classroom_id.length === 0) {
          classroomsByGradesOrSchool = await dispatch(
            getClassroomsBySchoolIdRSPA.request({ schoolId })
          ).then(res => res)
        }
      }

      const eventValues = {
        destinationType: values.destination_type,
        school_id: schoolId,
        classroom_id: !classroomsByGradesOrSchool
          ? Array.isArray(values.classroom_id)
            ? values.classroom_id.map(classroom => classroom.value)
            : values.classroom_id
          : classroomsByGradesOrSchool &&
            classroomsByGradesOrSchool.map(classroom => classroom.id)
      }

      if (eventValues.classroom_id?.length === 0) {
        throw new SubmissionError({ _error: true })
      } else {
        Object.assign(newValues, { ...eventValues })
        if (props.isEdit) {
          delete newValues.user_id
          if (!(newValues.cover_image instanceof File)) {
            delete newValues.cover_image
          }
          dispatch(
            editEvent({
              id: props.eventId,
              data: newValues,
              filesToDelete: values.filesToDelete
            })
          )
        } else {
          dispatch(createEvents(newValues))
        }
      }
    }
  })(AnnouncementsForm)
)
