import React, { useEffect } from 'react'
import './pages.css'
import { connect } from 'react-redux'

import Label from '../components/label/Label'
import Button from '../components/button/Button'
import Tab from '../components/tab/Tab'
import { occupationsObject } from '../utils/dicts'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { Input } from '@material-ui/core'

import {
  getContents,
  deleteContent,
  clearCurrentContent
} from '../store/contents/actions'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '98%',
    background: 'white',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)',
    padding: '1rem'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  createBtn: {
    color: '#FFFF',
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  inputText: {
    border: '1px solid #ADB8CC',
    borderRadius: 8,
    height: 48,
    paddingLeft: 8
  },
  inputComponent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12
  },
  inputsWrapper: {
    display: 'flex',
    gap: 16
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchBtns: {
    display: 'flex',
    alignItems: 'center',
    gap: 12
  },
  filtersSection: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  }
}))

const mapStateToProps = ({ auth, contents, school }) => ({
  user: auth.user,
  contents: contents.contents,
  isFetching: contents.isFetching,
  isError: contents.isError,
  school: school.currentSchool,
  schoolIsFetching: school.isFetching,
  userOccupations: auth.currentOccupation
})

const mapDispatchToProps = {
  getContents,
  deleteContent,
  clearCurrentContent
}

const ContentsPage = ({
  getContents,
  contents,
  history,
  search,
  deleteContent,
  isFetching,
  user,
  school,
  schoolIsFetching,
  userOccupations,
  clearCurrentContent
}) => {
  const classes = useStyles()
  const [contentTitle, setContentTitle] = React.useState('')
  const [contentId, setContentId] = React.useState()
  const [dispatchSearch, setDispatchSearch] = React.useState(false)

  const formattedData = contents?.data?.map(content => {
    const themeId = content.attributes.theme_id
    const themeInfo = contents.included.find(
      item => item.type === 'themes' && themeId === item.id * 1
    )
    const categoryId = content && content.attributes.category_id
    const categoryInfo = contents.included.find(
      item => item.type === 'categories' && categoryId === item.id * 1
    )
    const segmentId = content.attributes.segment_id
    const segmentInfo = contents.included.find(
      item => item.type === 'segments' && segmentId === item.id * 1
    )
    const occupationId = content.attributes.occupation_id
    const occupationInfo = contents.included.find(
      item => item.type === 'occupations' && occupationId === item.id * 1
    )
    return {
      ...content,
      attributes: {
        ...content.attributes,
        theme: { ...themeInfo?.attributes },
        category: { ...categoryInfo?.attributes },
        segment: { ...segmentInfo?.attributes },
        occupation: {
          ...occupationInfo?.attributes,
          name: occupationsObject[occupationInfo?.attributes.name]
        }
      }
    }
  })

  const handleSearch = () => {
    setDispatchSearch(true)
  }
  const handleClearFields = () => {
    setContentTitle('')
    setContentId('')
    setDispatchSearch(true)
  }

  useEffect(
    () => {
      getContents(search)
    },
    [search]
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Label>Conteúdos</Label>
        <Button
          data-testid='btn-create-content'
          className={classes.createBtn}
          startIcon={<AddIcon />}
          onclick={async () => {
            await clearCurrentContent()
            history.push('/contents/create')
          }}
        >
          Criar Conteúdo
        </Button>
      </div>
      <div className={classes.filtersSection}>
        <div className={classes.inputsWrapper}>
          <div className={classes.inputComponent}>
            <label>ID:</label>
            <Input
              className={classes.inputText}
              type='number'
              disableUnderline
              value={contentId}
              placeholder={'Busque pelo ID'}
              onChange={event => setContentId(event.target.value)}
            />
          </div>
          <div className={classes.inputComponent}>
            <label>Título</label>
            <Input
              className={classes.inputText}
              type='text'
              disableUnderline
              value={contentTitle}
              placeholder={'Busque pelo título'}
              onChange={event => setContentTitle(event.target.value)}
            />
          </div>
        </div>
        <div className={classes.searchBtns}>
          <Button className={classes.clearBtn} onClick={handleClearFields}>
            Limpar
          </Button>
          <Button
            onClick={handleSearch}
            type='button'
            className={classes.applyBtn}
          >
            Aplicar
          </Button>
        </div>
      </div>
      <Tab
        data={{ ...contents, data: formattedData }}
        fetchItems={getContents}
        deleteItem={deleteContent}
        itemsIsFetching={isFetching}
        school={school?.school}
        params={{
          'q[id_eq]': contentId,
          'q[title_cont]': contentTitle
        }}
        dispatchSearch={dispatchSearch}
        setDispatchSearch={setDispatchSearch}
        schoolIsFetching={schoolIsFetching}
        search={search}
        userOccupations={userOccupations}
        extraContent={[
          {
            title: 'Foco',
            attr: 'occupation'
          },
          {
            title: 'Tema',
            attr: 'theme'
          },
          {
            title: 'Categoria',
            attr: 'category'
          },
          {
            title: 'Segmento',
            attr: 'segment'
          }
        ]}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentsPage)
